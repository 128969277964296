import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteUncontrolled,
  ISelectType,
  ModalFilters,
  Table,
  useFetch,
} from "@4uhub/lib4uhub";

import MainApiService, { MainApi } from "../../../../services/mainApi.service";

import { IIpmTasyRelationships } from "../../../../models/ipm-tasy";
import { Chip } from "@mui/material";
import { useAppSelector } from "../../../../store/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import CellData from "../../../../components/UI/CellData/CellData";
import { SelectRelationshipType } from "./SelectRelationshipType/SelectRelationshipType";

const ACCESS_TOKEN_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentSetRelationships/";
const accessTokenService = new MainApiService<IIpmTasyRelationships>(
  ACCESS_TOKEN_ROUTE
);

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const translationPath = "page.4uBus.relationships.ipmTasy.";

const IpmTasyPage = () => {
  const { pathname } = useLocation();

  const [relationshipType, setRelationshipType] = useState<ISelectType | null>(
    null
  );

  const [directionTypeOptions, setDirectionTypeOptions] = useState<
    ISelectType[]
  >([]);

  const [directionType, setDirectionType] = useState<ISelectType | null>(null);

  const [filterParams, setFilterParams] = useState<
    Record<string, any> | undefined
  >();

  const { sendRequest } = useFetch(genericService.list);

  const fetchDirectionTypeOptions = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "DIRECTION_TYPE",
    });
    if (data && success) {
      setDirectionTypeOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchDirectionTypeOptions();
  }, [fetchDirectionTypeOptions]);

  const currentPage = useAppSelector((state) =>
    state.menu.items.find(
      (menu) => menu.link === `/${pathname.split("/").slice(4).join("/")}`
    )
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { extension } = useParams();

  const filterHandler = () => {
    setFilterParams({
      RelationshipTypeCode: relationshipType?.code,
      DirectionTypeCode: directionType?.code,
    });
  };

  const cleanFilterHandler = () => {
    setDirectionType(null);
    setRelationshipType(null);
    setFilterParams(undefined);
  };

  const columns: GridColDef<IIpmTasyRelationships>[] = [
    {
      field: "setRelationshipType",
      headerName:
        t(translationPath + "relationship_type") || "Relationship Type",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value.name} />;
        }
      },
    },
    {
      field: "directionType",
      headerName: t(translationPath + "direction_type") || "Direction Type",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value.name} />;
        }
      },
    },
    {
      field: "originName",
      headerName: t(translationPath + "origin_name") || "Origin name",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "originValue",
      headerName: t(translationPath + "origin_value") || "Origin value",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "destinyName",
      headerName: t(translationPath + "destiny_name") || "Destiny name",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "destinyValue",
      headerName: t(translationPath + "destiny_value") || "Destiny value",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "default",
      headerName: t(translationPath + "default") || "Default",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "is_default")
                : t(translationPath + "is_not_default")
            }
            color={params.value ? "success" : "default"}
          />
        );
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IIpmTasyRelationships>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      ExtensionCode: extension,
    };
  }, [extension]);

  return (
    <Table<IIpmTasyRelationships>
      showDefaultMacroFilters={false}
      service={accessTokenService}
      columns={columns}
      title={
        currentPage ? t(currentPage.name) : t(translationPath + "relationship")
      }
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      serviceProps={
        filterParams ? { ExtensionCode: extension, ...filterParams } : props
      }
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a relationship"
      }
      addButtonLabel={
        t(translationPath + "add_relationship") || "Add Relationship"
      }
      macroFilters={() => (
        <ModalFilters
          badgeCount={
            filterParams
              ? Object.keys(filterParams).filter(
                  (chave) => filterParams[chave] !== undefined
                ).length
              : 0
          }
          actionsDisabled={!relationshipType && !directionType}
          onFilter={filterHandler}
          onCleanFilter={cleanFilterHandler}
        >
          <>
            <SelectRelationshipType
              relationshipType={relationshipType}
              setRelationshipType={setRelationshipType}
            />
            <AutoCompleteUncontrolled
              label={t(translationPath + "direction_type")}
              size="small"
              value={directionType}
              onValueSelected={setDirectionType}
              options={directionTypeOptions}
            />
          </>
        </ModalFilters>
      )}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default IpmTasyPage;
