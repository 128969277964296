import { AutoComplete, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScheduleAppointmentService } from "../../../../../Schedule.service";
import { ISpeciality } from "../../../../../model";



const Speciality = () => {
  const [specialitys, setSpecialitys] = useState<ISpeciality[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getSpecialitys);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { success, data } = await sendRequest(null);

    if (success && data) {
      setSpecialitys(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      options={specialitys.map((s) => ({ id: s.integrationId, name: s.name }))}
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("components.scheduleAssistant.newSchedule.search.speciality")}
      name="search.speciality"
      sx={{ mb: 2 }}
    />
  );
};

export default memo(Speciality);
