import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  AutoCompleteMulti,
  FormCheckBox,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { TTicketServiceForm, ticketServiceSchema } from "./TicketServiceSchema";
import MainApi from "../../../../../../services/mainApi.service";
import { useCallback, useEffect, useState } from "react";
import TicketServService from "../../../../../../services/ticket.service";
import SitesAppsService from "../../../../../../services/sitesApps.service";
import { SelectMessageReceivedEvent } from "./SelectMessageReceivedEvent/SelectMessageReceivedEvent";

const ticketService = new TicketServService();

const siteAppExtensionService = new SitesAppsService();

const TICKET_CHANNELS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels/";
const ticketChannelsService = new MainApi<ISelectType>(TICKET_CHANNELS_ROUTE);

const translationPath = "chatBot.page.register.ticketService.";

const TicketService = () => {
  const { t } = useTranslation();
  const [siteAppOptions, setSiteAppOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const loadSiteApp = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "3" });
    if (data && success) {
      setSiteAppOptions(
        data.map((item) => ({ id: item.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    loadSiteApp();
  }, [loadSiteApp]);

  const { sendRequest: save, loading: sLoading } = useFetch(
    ticketService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    ticketService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    ticketService.getItemById
  );

  const handleSave = (v: TTicketServiceForm) => {
    return save({
      ...v,
      siteAppExtensions: v.siteAppExtensions.map((siteApp) => siteApp.id),
      ticketChannelInitialId: v.ticketChannelInitial.id,
      ticketServiceTypeId: v.ticketServiceType.id,
      ticketMessageReceivedEventId: v.ticketMessageReceivedEvent
        ? v.ticketMessageReceivedEvent.id
        : undefined,
    });
  };

  const updateHandler = (v: TTicketServiceForm, id: string) =>
    update({
      item: {
        ...v,
        siteAppExtensions: v.siteAppExtensions.map((siteApp) => siteApp.id),
        ticketChannelInitialId: v.ticketChannelInitial.id,
        ticketServiceTypeId: v.ticketServiceType.id,
        ticketMessageReceivedEventId: v.ticketMessageReceivedEvent
          ? v.ticketMessageReceivedEvent.id
          : undefined,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TTicketServiceForm = {
          ...data,
          siteAppExtensions: data.siteAppExtensions.map((item) => ({
            id: item.id,
            name: item.siteApp.name,
          })),
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketServiceForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "ticket_service")}
      schema={ticketServiceSchema}
      subtitle={t(translationPath + "new_ticket_service")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "ticket_service_type")}
              name="ticketServiceType"
              request={ticketService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "ticket_channel_initial")}
              name="ticketChannelInitial"
              request={ticketChannelsService.list}
            />
          </Grid>

          <SelectMessageReceivedEvent />

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              size="small"
              label={t(translationPath + "site_app_extensions")}
              name="siteAppExtensions"
              options={siteAppOptions}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormCheckBox
              name="showMessageDisableArea"
              label={`${t(translationPath + "show_message_disable_area")}`}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default TicketService;
