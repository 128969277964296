import {
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IFormStepperHeaderProps } from "./models";
import { genericMemo } from "../../../../../../components/SubFormArray/genericMemo";

const FormStepperHeader = <T extends FieldValues>({
  steps,
  activeStep,
}: IFormStepperHeaderProps<T>) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  if (!matches) {
    return (
      <Stepper activeStep={activeStep}>
        {steps.map(({ label }, index) => {
          return (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }

  return (
    <Stack>
      <Typography>
        {activeStep + 1} / {steps.length}
      </Typography>
      <Typography variant="h6">{t(steps[activeStep].label)}</Typography>
    </Stack>
  );
};

export default genericMemo(FormStepperHeader);
