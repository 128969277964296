import LegalEntityPage from "../pages/registers/legalEntity/LegalEntityPage";
import GenericsPage from "../pages/globals/generics/GenericsPage";
import Generic from "../pages/globals/generics/GenericForm/Generic";
import LegalEntity from "../pages/registers/legalEntity/LegalEntityForm/LegalEntity";

import { Outlet, RouteObject } from "react-router-dom";
import RolesPage from "../pages/admSystem/roles/RolesPage";
import Roles from "../pages/admSystem/roles/RolesForm/Roles";
import LanguagesPage from "../pages/globals/languages/LanguagesPage";
import Languages from "../pages/globals/languages/LanguagesForm/Languages";
import IndividualsPage from "../pages/registers/individuals/IndividualsPages";
import Individual from "../pages/registers/individuals/IndividualForm/Individual";
import UsersPage from "../pages/admSystem/users/UsersPage";
import User from "../pages/admSystem/users/UserForm/UserForm";
import EstablishmentsPage from "../pages/admSystem/establishments/EstablishmentsPage";
import Establishment from "../pages/admSystem/establishments/EstablishmentForm/EstablishmentForm";
import CountriesPage from "../pages/globals/countries/CountriesPage";
import Country from "../pages/globals/countries/CountryForm/Country";
import WelcomePage from "../pages/welcomePage";
import SettingsPage from "../pages/settings/SettingsPage";
import { DomainProvider } from "@4uhub/lib4uhub";
import ExtensionsPage from "../pages/globals/extensions/ExtensionsPage";
import Extension from "../pages/globals/extensions/ExtensionForm/Extension";
import DefaultSettingsForm from "../pages/globals/extensions/DefaultSettings/DefaultSettingsForm/DefaultSettingsForm";
import PriceRulesForm from "../pages/globals/extensions/PriceRules/PriceRulesForm/PriceRulesForm";
import PriceRulesPage from "../pages/globals/extensions/PriceRules/PriceRulesPage";
import DefaultSettingsPage from "../pages/globals/extensions/DefaultSettings/DefaultSettingsPage";
import CompanyGroupsPage from "../pages/admSystem/companyGroups/CompanyGroupsPage";
import CompanyGroup from "../pages/admSystem/companyGroups/CompanyGroupForm/CompanyGroup";
import Company from "../pages/admSystem/companies/CompanyForm/Company";
import CompaniesPage from "../pages/admSystem/companies/CompaniesPage";
import AditionalSettingsPage from "../pages/admSystem/aditionalSettings/AditionalSettingsPage";
import Province from "../pages/globals/provinces/ProvinceForm/ProvinceForm";
import ProvincesPage from "../pages/globals/provinces/ProvincesPage";
import CitiesPage from "../pages/globals/cities/CitiesPage";
import City from "../pages/globals/cities/CityForm/City";
import NeighborhoodsPage from "../pages/globals/neighborhoods/NeighborhoodsPage";
import Neighborhood from "../pages/globals/neighborhoods/NeighborhoodForm/Neighborhood";
import BanksPage from "../pages/globals/banks/BanksPage";
import Bank from "../pages/globals/banks/BankForm/Bank";
import JobsPage from "../pages/globals/jobs/JobsPage";
import LogsPage from "../pages/logsPage/logsPage";
import ExtensionsStorePage from "../pages/extensionsStore/ExtensionsStorePage";
import ExtensionSetting from "../pages/extensionsStore/ExtensionSettings/ExtensionSettingsForm/ExtensionSetting";
import ExtensionSettings from "../pages/extensionsStore/ExtensionSettings/ExtensionSettings";
import Register from "../pages/applications/Register/RegisterForm/Register";
import SettingsCache from "../pages/settings/Settings/SettingsSystem/SettingsCache/SettingsCache";
import SettingsCorreios from "../pages/settings/Settings/SettingsSystem/SettingsCorreios/SettingsCorreios";
import SettingsCentralBank from "../pages/settings/Settings/SettingsSystem/SettingsCentralBank/SettingsCentralBank";
import SettingsGeolocalization from "../pages/settings/Settings/SettingsSystem/SettingsGeolocalization/SettingsGeolocalization";
import ParamDefaultEstablishmentPage from "../pages/settings/Settings/SettingsSystem/paramDefaultEstabishment/ParamDefaultEstablishmentPage";
import ParamDefaultEstablishment from "../pages/settings/Settings/SettingsSystem/paramDefaultEstabishment/ParamDefaultEstablishmentForm/ParamDefaultEstablishment";
import HolidaysPage from "../pages/settings/Settings/SettingsGeneral/holidays/HolidaysPage";
import Holiday from "../pages/settings/Settings/SettingsGeneral/holidays/HolidayForm/Holiday";
import EstablishmentParamsPage from "../pages/settings/Settings/SettingsGeneral/establishmentParams/EstablishmentParamsPage";
import EstablishmentParam from "../pages/settings/Settings/SettingsGeneral/establishmentParams/EstablishmentParamsForm/EstablishmentParam";
import CardsTemplatesPage from "../pages/settings/Settings/SettingsSiteApp/CardsTemplates/CardsTemplatesPage";
import CardTemplate from "../pages/settings/Settings/SettingsSiteApp/CardsTemplates/CardTemplateForm/CardTemplate";
import ProtocolGeneratePage from "../pages/settings/Settings/SettingsCrm/ProtocolGenerate/ProtocolGeneratePage";
import ProtocolGenerate from "../pages/settings/Settings/SettingsCrm/ProtocolGenerate/ProtocolGenerateForm/ProtocolGenerate";
import TicketServicePage from "../pages/settings/Settings/SettingsCrm/ticketService/TicketServicePage";
import TicketServiceWhats from "../pages/settings/Settings/SettingsCrm/ticketService/TicketServiceWhatsForm/TicketServiceWhats";
import TicketService from "../pages/settings/Settings/SettingsCrm/ticketService/TicketServiceForm/TicketService";
import ServiceDayPage from "../pages/settings/Settings/SettingsCrm/ServiceDays/ServiceDayPage";
import ServiceDay from "../pages/settings/Settings/SettingsCrm/ServiceDays/ServiceDayForm/ServiceDay";
import TicketServiceAreaPage from "../pages/settings/Settings/SettingsCrm/TicketServiceArea/TicketServiceAreaPage";
import TicketServiceArea from "../pages/settings/Settings/SettingsCrm/TicketServiceArea/TicketServiceAreaForm/TicketServiceArea";
import TicketServiceMessagePage from "../pages/settings/Settings/SettingsCrm/TicketServiceMessage/TicketServiceMessagePage";
import TicketServiceMessage from "../pages/settings/Settings/SettingsCrm/TicketServiceMessage/TicketServiceMessageForm/TicketServiceMessage";
import RegisterPage from "../pages/applications/Register/RegisterPage";
import DashBoardPanelsPage from "../pages/myDashboards/Panels/DashBoardPanels";
import DashBoardPanel from "../pages/myDashboards/Panels/DashBoardPanelForm/DashBoardPanel";
import Panel from "../pages/myDashboards/Panels/Panel";
import ConfigsPage from "../pages/settings/Settings/Settings4uBus/Configs/ConfigsPage";
import Config from "../pages/settings/Settings/Settings4uBus/Configs/ConfigsForm/Config";
import IpmGeneralSettingsPage from "../pages/settings/Settings/Settings4uBus/Ipm/IpmGeneralSettings/IpmGeneralSettingsPage";
import IpmGeneralSetting from "../pages/settings/Settings/Settings4uBus/Ipm/IpmGeneralSettings/IpmGeneralSettingForm/IpmGeneralSetting";
import SyncPage from "../pages/settings/Settings/Settings4uBus/Sync/SyncPage";
import TicketAutoFinishRulesPage from "../pages/settings/Settings/SettingsCrm/TicketAutoFinishRule/TicketAutoFinishRulesPage";
import TicketAutoFinishRule from "../pages/settings/Settings/SettingsCrm/TicketAutoFinishRule/TicketAutoFinishRuleForm/TicketAutoFinishRule";
import WorkGroupTypesPage from "../pages/settings/Settings/SettingsPreventMedicine/WorkGroupTypes/WorkGroupTypesPage";
import WorkGroupType from "../pages/settings/Settings/SettingsPreventMedicine/WorkGroupTypes/WorkGroupTypeForm/WorkGroupType";
import HealthProgramTypesPage from "../pages/settings/Settings/SettingsPreventMedicine/HealthProgramTypes/HealthProgramTypesPage";
import HealthProgramType from "../pages/settings/Settings/SettingsPreventMedicine/HealthProgramTypes/HealthProgramTypeForm/HealthProgramType";
import HealthChecksPage from "../pages/settings/Settings/SettingsGeneral/healthChecks/HealthChecksPage";
import HealthCheck from "../pages/settings/Settings/SettingsGeneral/healthChecks/HealthCheckForm/HealthCheck";
import ScheduleCheckInPage from "../pages/settings/Settings/SettingsSchedule/TasySchedule/ScheduleCheckIn/ScheduleCheckInPage";
import ScheduleCheckIn from "../pages/settings/Settings/SettingsSchedule/TasySchedule/ScheduleCheckIn/ScheduleCheckInForm/ScheduleCheckIn";
import ScheduleConfirmationPage from "../pages/settings/Settings/SettingsSchedule/TasySchedule/ScheduleConfirmation/ScheduleConfirmationPage";
import ScheduleConfirmation from "../pages/settings/Settings/SettingsSchedule/TasySchedule/ScheduleConfirmation/ScheduleConfirmationForm/ScheduleConfirmation";
import QuestionsPage from "../pages/Evaluations/Questions/QuestionsPage";
import Question from "../pages/Evaluations/Questions/QuestionForm/Question";
import HealthChecksLogsPage from "../pages/Logs/HealthChecksLogsPage";
import ProtocolsDefaultPage from "../pages/settings/Settings/SettingsCrm/ProtocolsDefault/ProtocolsDefaultPage";
import ProtocolDefault from "../pages/settings/Settings/SettingsCrm/ProtocolsDefault/ProtocolDefaultForm/ProtocolDefault";
import FormsPage from "../pages/Evaluations/Forms/FormsPage";
import Form from "../pages/Evaluations/Forms/FormsForm.tsx/Form";
import EventsPage from "../pages/settings/Settings/SettingsCrm/Events/EventsPage";
import Event from "../pages/settings/Settings/SettingsCrm/Events/EventForm/Event";
import LogRegisterErrors from "../pages/Logs/LogRegisterErrors";

const dashboardRoutes: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "globals",
    element: <Outlet />,
    children: [
      {
        path: "generics",
        element: (
          <DomainProvider value={{ domainName: "generic" }}>
            <GenericsPage />
          </DomainProvider>
        ),
      },
      {
        path: "generics/new",
        element: (
          <DomainProvider
            value={{ domainName: "generic" }}
            actions={["Create"]}
          >
            <Generic />
          </DomainProvider>
        ),
      },
      {
        path: "generics/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "generic" }} actions={["Read"]}>
            <Generic />
          </DomainProvider>
        ),
      },
      {
        path: "extensions",
        element: (
          <DomainProvider value={{ domainName: "extension" }}>
            <ExtensionsPage />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Create"]}
          >
            <Extension />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/default-settings",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <DefaultSettingsPage />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/default-settings/new",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <DefaultSettingsForm />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/default-settings/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <DefaultSettingsForm />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/price-rules",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <PriceRulesPage />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/price-rules/new",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <PriceRulesForm />
          </DomainProvider>
        ),
      },
      {
        path: "extensions/:extId/price-rules/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "extension" }}
            actions={["Read"]}
          >
            <PriceRulesForm />
          </DomainProvider>
        ),
      },

      {
        path: "languages",
        element: (
          <DomainProvider value={{ domainName: "language" }}>
            <LanguagesPage />
          </DomainProvider>
        ),
      },
      {
        path: "languages/new",
        element: (
          <DomainProvider
            value={{ domainName: "language" }}
            actions={["Create"]}
          >
            <Languages />
          </DomainProvider>
        ),
      },
      {
        path: "languages/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "language" }} actions={["Read"]}>
            <Languages />
          </DomainProvider>
        ),
      },
      {
        path: "countries",
        element: (
          <DomainProvider value={{ domainName: "country" }}>
            <CountriesPage />
          </DomainProvider>
        ),
      },
      {
        path: "countries/new",
        element: (
          <DomainProvider
            value={{ domainName: "country" }}
            actions={["Create"]}
          >
            <Country />
          </DomainProvider>
        ),
      },
      {
        path: "countries/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "country" }} actions={["Read"]}>
            <Country />
          </DomainProvider>
        ),
      },
      {
        path: "provinces",
        element: (
          <DomainProvider value={{ domainName: "provinces" }}>
            <ProvincesPage />
          </DomainProvider>
        ),
      },
      {
        path: "provinces/new",
        element: (
          <DomainProvider
            value={{ domainName: "provinces" }}
            actions={["Create"]}
          >
            <Province />
          </DomainProvider>
        ),
      },
      {
        path: "provinces/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "provinces" }}
            actions={["Read"]}
          >
            <Province />
          </DomainProvider>
        ),
      },
      {
        path: "cities",
        element: (
          <DomainProvider value={{ domainName: "cities" }}>
            <CitiesPage />
          </DomainProvider>
        ),
      },
      {
        path: "cities/new",
        element: (
          <DomainProvider value={{ domainName: "cities" }} actions={["Create"]}>
            <City />
          </DomainProvider>
        ),
      },
      {
        path: "cities/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "cities" }} actions={["Read"]}>
            <City />
          </DomainProvider>
        ),
      },
      {
        path: "neighborhoods",
        element: (
          <DomainProvider value={{ domainName: "neighborhoods" }}>
            <NeighborhoodsPage />
          </DomainProvider>
        ),
      },
      {
        path: "neighborhoods/new",
        element: (
          <DomainProvider
            value={{ domainName: "neighborhoods" }}
            actions={["Create"]}
          >
            <Neighborhood />
          </DomainProvider>
        ),
      },
      {
        path: "neighborhoods/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "neighborhoods" }}
            actions={["Read"]}
          >
            <Neighborhood />
          </DomainProvider>
        ),
      },
      {
        path: "banks",
        element: (
          <DomainProvider value={{ domainName: "banks" }}>
            <BanksPage />
          </DomainProvider>
        ),
      },
      {
        path: "banks/new",
        element: (
          <DomainProvider value={{ domainName: "banks" }} actions={["Create"]}>
            <Bank />
          </DomainProvider>
        ),
      },
      {
        path: "banks/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "banks" }} actions={["Read"]}>
            <Bank />
          </DomainProvider>
        ),
      },
      {
        path: "jobs",
        element: (
          <DomainProvider value={{ domainName: "jobs" }}>
            <JobsPage />
          </DomainProvider>
        ),
      },
    ],
  },

  {
    path: "people",
    element: <Outlet />,
    children: [
      {
        path: "legalEntities",
        element: (
          <DomainProvider value={{ domainName: "legalentity" }}>
            <LegalEntityPage />
          </DomainProvider>
        ),
        id: "Legal Entity Page",
      },
      {
        path: "legalEntities/new",
        element: (
          <DomainProvider
            value={{ domainName: "legalentity" }}
            actions={["Create"]}
          >
            <LegalEntity />
          </DomainProvider>
        ),
      },
      {
        path: "legalEntities/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "legalentity" }}
            actions={["Read"]}
          >
            <LegalEntity />
          </DomainProvider>
        ),
      },
      {
        path: "logs",
        element: <LogsPage />,
      },
      {
        path: "individuals",
        element: (
          <DomainProvider value={{ domainName: "individual" }}>
            <IndividualsPage />
          </DomainProvider>
        ),
      },
      {
        path: "individuals/new",
        element: (
          <DomainProvider
            value={{ domainName: "individual" }}
            actions={["Create"]}
          >
            <Individual />
          </DomainProvider>
        ),
      },
      {
        path: "individuals/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "individual" }}
            actions={["Read"]}
          >
            <Individual />
          </DomainProvider>
        ),
      },
    ],
  },
  {
    path: "myDashboards",
    element: <Outlet />,
    children: [
      {
        path: "dashboards",
        element: (
          <DomainProvider value={{ domainName: "dashboardcomponent" }}>
            <DashBoardPanelsPage />
          </DomainProvider>
        ),
      },
      {
        path: "dashboards/new",
        element: (
          <DomainProvider
            value={{ domainName: "dashboardcomponent" }}
            actions={["Create"]}
          >
            <DashBoardPanel />
          </DomainProvider>
        ),
      },
      {
        path: "dashboards/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "dashboardcomponent" }}
            actions={["Read"]}
          >
            <DashBoardPanel />
          </DomainProvider>
        ),
      },
      {
        path: "dashboards/panel/:panelId",
        element: (
          <DomainProvider value={{ domainName: "dashboardcomponent" }}>
            <Panel />
          </DomainProvider>
        ),
      },
      {
        path: "view",
        element: (
          <DomainProvider value={{ domainName: "panels" }}>
            <DashBoardPanelsPage isView />
          </DomainProvider>
        ),
      },
      {
        path: "view/panel/:panelId",
        element: (
          <DomainProvider value={{ domainName: "panels" }}>
            <Panel isView />
          </DomainProvider>
        ),
      },
    ],
  },
  {
    path: "admSystem",
    element: <Outlet />,
    children: [
      {
        path: "roles",
        element: (
          <DomainProvider value={{ domainName: "role" }}>
            <RolesPage />
          </DomainProvider>
        ),
      },
      {
        path: "roles/new",
        element: (
          <DomainProvider value={{ domainName: "role" }} actions={["Create"]}>
            <Roles />
          </DomainProvider>
        ),
      },
      {
        path: "roles/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "role" }} actions={["Read"]}>
            <Roles />
          </DomainProvider>
        ),
      },
      {
        path: "establishmentParams",
        element: (
          <DomainProvider value={{ domainName: "establishmentparams" }}>
            <EstablishmentParamsPage />
          </DomainProvider>
        ),
      },
      {
        path: "establishmentParams/new",
        element: (
          <DomainProvider
            value={{ domainName: "establishmentparams" }}
            actions={["Create"]}
          >
            <EstablishmentParam />
          </DomainProvider>
        ),
      },
      {
        path: "establishmentParams/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "establishmentparams" }}
            actions={["Read"]}
          >
            <EstablishmentParam />
          </DomainProvider>
        ),
      },
      {
        path: "users",
        element: (
          <DomainProvider
            value={{
              domainName: "users",
            }}
          >
            <UsersPage />
          </DomainProvider>
        ),
      },
      {
        path: "users/new",
        element: (
          <DomainProvider value={{ domainName: "users" }} actions={["Create"]}>
            <User />
          </DomainProvider>
        ),
      },
      {
        path: "users/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "users" }} actions={["Read"]}>
            <User />
          </DomainProvider>
        ),
      },
      {
        path: "establishments",
        element: (
          <DomainProvider
            value={{
              domainName: "establishment",
            }}
          >
            <EstablishmentsPage />
          </DomainProvider>
        ),
      },
      {
        path: "establishments/new",
        element: (
          <DomainProvider
            value={{ domainName: "establishment" }}
            actions={["Create"]}
          >
            <Establishment />
          </DomainProvider>
        ),
      },
      {
        path: "establishments/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "establishment" }}
            actions={["Read"]}
          >
            <Establishment />
          </DomainProvider>
        ),
      },
      {
        path: "aditionalSettings",
        element: <AditionalSettingsPage />,
      },
      {
        path: "aditionalSettings/cardsTemplates",
        element: (
          <DomainProvider
            value={{ domainName: "establishmentparammodelcards" }}
          >
            <CardsTemplatesPage />
          </DomainProvider>
        ),
      },
      {
        path: "aditionalSettings/cardsTemplates/new",
        element: (
          <DomainProvider
            value={{ domainName: "establishmentparammodelcards" }}
            actions={["Create"]}
          >
            <CardTemplate />
          </DomainProvider>
        ),
      },
      {
        path: "aditionalSettings/cardsTemplates/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "establishmentparammodelcards" }}
            actions={["Read"]}
          >
            <CardTemplate />
          </DomainProvider>
        ),
      },
      {
        path: "companygroups",
        element: (
          <DomainProvider value={{ domainName: "companygroups" }}>
            <CompanyGroupsPage />
          </DomainProvider>
        ),
      },
      {
        path: "companygroups/new",
        element: (
          <DomainProvider value={{ domainName: "companygroups" }}>
            <CompanyGroup />
          </DomainProvider>
        ),
      },
      {
        path: "companygroups/:id/edit",
        element: (
          <DomainProvider value={{ domainName: "companygroups" }}>
            <CompanyGroup />
          </DomainProvider>
        ),
      },
      {
        path: "companies",
        element: (
          <DomainProvider value={{ domainName: "companies" }}>
            <CompaniesPage />
          </DomainProvider>
        ),
      },
      {
        path: "companies/new",
        element: (
          <DomainProvider
            value={{ domainName: "companies" }}
            actions={["Create"]}
          >
            <Company />
          </DomainProvider>
        ),
      },
      {
        path: "companies/:id/edit",
        element: (
          <DomainProvider
            value={{ domainName: "companies" }}
            actions={["Read"]}
          >
            <Company />
          </DomainProvider>
        ),
      },
    ],
  },
  {
    path: "applications/register",
    element: (
      <DomainProvider value={{ domainName: "siteapp" }} actions={["Read"]}>
        <RegisterPage />
      </DomainProvider>
    ),
  },
  {
    path: "applications/register/new",
    element: (
      <DomainProvider value={{ domainName: "siteapp" }} actions={["Create"]}>
        <Register />
      </DomainProvider>
    ),
  },
  {
    path: "applications/register/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "siteapp" }} actions={["Read"]}>
        <Register />
      </DomainProvider>
    ),
  },
  {
    path: "extensions-store",
    element: (
      <DomainProvider value={{ domainName: "siteappextension" }}>
        <ExtensionsStorePage />
      </DomainProvider>
    ),
  },
  {
    path: "extension-settings/:siteAppId/:extensionId",
    element: (
      <DomainProvider value={{ domainName: "extensionconfig" }}>
        <ExtensionSettings />
      </DomainProvider>
    ),
  },
  {
    path: "extension-settings/:siteAppId/:extensionId/new",
    element: (
      <DomainProvider
        value={{ domainName: "extensionconfig" }}
        actions={["Create"]}
      >
        <ExtensionSetting />
      </DomainProvider>
    ),
  },
  {
    path: "extension-settings/:siteAppId/:extensionId/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "extensionconfig" }}
        actions={["Read"]}
      >
        <ExtensionSetting />
      </DomainProvider>
    ),
  },

  {
    path: "settings",
    element: (
      <DomainProvider value={{ domainName: "settings" }}>
        <SettingsPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/cache",
    element: (
      <DomainProvider
        value={{ domainName: "generalsettings" }}
        actions={["Read"]}
      >
        <SettingsCache />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/paramsDefaultEstablishment",
    element: (
      <DomainProvider value={{ domainName: "paramdefaultestablishment" }}>
        <ParamDefaultEstablishmentPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/paramsDefaultEstablishment/new",
    element: (
      <DomainProvider value={{ domainName: "paramdefaultestablishment" }}>
        <ParamDefaultEstablishment />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/paramsDefaultEstablishment/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "paramdefaultestablishment" }}>
        <ParamDefaultEstablishment />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/holidays",
    element: (
      <DomainProvider value={{ domainName: "holidays" }}>
        <HolidaysPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/holidays/new",
    element: (
      <DomainProvider value={{ domainName: "holidays" }} actions={["Create"]}>
        <Holiday />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/holidays/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "holidays" }} actions={["Read"]}>
        <Holiday />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/establishmentParams",
    element: (
      <DomainProvider value={{ domainName: "establishmentparams" }}>
        <EstablishmentParamsPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/establishmentParams/new",
    element: (
      <DomainProvider
        value={{ domainName: "establishmentparams" }}
        actions={["Create"]}
      >
        <EstablishmentParam />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/establishmentParams/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "establishmentparams" }}
        actions={["Read"]}
      >
        <EstablishmentParam />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/healthChecks",
    element: (
      <DomainProvider value={{ domainName: "healthcheck" }}>
        <HealthChecksPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/healthChecks/new",
    element: (
      <DomainProvider
        value={{ domainName: "healthcheck" }}
        actions={["Create"]}
      >
        <HealthCheck />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/healthChecks/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "healthcheck" }} actions={["Read"]}>
        <HealthCheck />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/cardsTemplates",
    element: (
      <DomainProvider value={{ domainName: "establishmentparammodelcards" }}>
        <CardsTemplatesPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/cardsTemplates/new",
    element: (
      <DomainProvider
        value={{ domainName: "establishmentparammodelcards" }}
        actions={["Create"]}
      >
        <CardTemplate />
      </DomainProvider>
    ),
  },
  {
    path: "settings/generalSettings/cardsTemplates/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "establishmentparammodelcards" }}
        actions={["Read"]}
      >
        <CardTemplate />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/generalSettings/correios",
    element: (
      <DomainProvider
        value={{ domainName: "generalsettings" }}
        actions={["Update"]}
      >
        <SettingsCorreios />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/generalSettings/centralBank",
    element: (
      <DomainProvider
        value={{ domainName: "generalsettings" }}
        actions={["Update"]}
      >
        <SettingsCentralBank />
      </DomainProvider>
    ),
  },
  {
    path: "settings/system/generalSettings/geolocalization",
    element: (
      <DomainProvider
        value={{ domainName: "generalsettings" }}
        actions={["Update"]}
      >
        <SettingsGeolocalization />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketProtocolRule",
    element: (
      <DomainProvider value={{ domainName: "ticketprotocolrule" }}>
        <ProtocolGeneratePage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketProtocolRule/new",
    element: (
      <DomainProvider value={{ domainName: "ticketprotocolrule" }}>
        <ProtocolGenerate />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketProtocolRule/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketprotocolrule" }}>
        <ProtocolGenerate />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketService",
    element: (
      <DomainProvider value={{ domainName: "ticketservice" }}>
        <TicketServicePage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketService/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketservice" }}
        actions={["Create"]}
      >
        <TicketService />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketService/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "ticketservice" }}
        actions={["Read"]}
      >
        <TicketService />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketService/whatsappConfig/:ticketServiceId/new",
    element: (
      <DomainProvider value={{ domainName: "ticketservice" }}>
        <TicketServiceWhats />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketService/whatsappConfig/:ticketServiceId/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketservice" }}>
        <TicketServiceWhats />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatBot/ticketServiceArea",
    element: (
      <DomainProvider value={{ domainName: "ticketservicearea" }}>
        <TicketServiceAreaPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatBot/ticketServiceArea/new",
    element: (
      <DomainProvider value={{ domainName: "ticketservicearea" }}>
        <TicketServiceArea />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatBot/ticketServiceArea/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketservicearea" }}>
        <TicketServiceArea />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatbot/ticketServiceDay",
    element: (
      <DomainProvider value={{ domainName: "ticketserviceday" }}>
        <ServiceDayPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatbot/ticketServiceDay/new",
    element: (
      <DomainProvider value={{ domainName: "ticketserviceday" }}>
        <ServiceDay />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatbot/ticketServiceDay/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketserviceday" }}>
        <ServiceDay />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketAutoFinishRule",
    element: (
      <DomainProvider value={{ domainName: "ticketautofinishrule" }}>
        <TicketAutoFinishRulesPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketAutoFinishRule/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketautofinishrule" }}
        actions={["Create"]}
      >
        <TicketAutoFinishRule />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketAutoFinishRule/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketautofinishrule" }}>
        <TicketAutoFinishRule />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketDefaultDocs",
    element: (
      <DomainProvider value={{ domainName: "ticketdefaultdoc" }}>
        <ProtocolsDefaultPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketDefaultDocs/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketdefaultdoc" }}
        actions={["Create"]}
      >
        <ProtocolDefault />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/ticketDefaultDocs/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketdefaultdoc" }}>
        <ProtocolDefault />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/events",
    element: (
      <DomainProvider value={{ domainName: "ticketevent" }}>
        <EventsPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/events/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketevent" }}
        actions={["Create"]}
      >
        <Event />
      </DomainProvider>
    ),
  },
  {
    path: "settings/crm/events/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketevent" }}>
        <Event />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatBot/ticketServiceMessage",
    element: (
      <DomainProvider value={{ domainName: "ticketservicemessage" }}>
        <TicketServiceMessagePage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/chatBot/ticketServiceMessage/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketservicemessage" }}>
        <TicketServiceMessage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/ipm/generalSettings",
    element: (
      <DomainProvider value={{ domainName: "barramentoipm" }}>
        <IpmGeneralSettingsPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/ipm/generalSettings/new",
    element: (
      <DomainProvider value={{ domainName: "barramentoipm" }}>
        <IpmGeneralSetting />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/ipm/generalSettings/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "barramentoipm" }}>
        <IpmGeneralSetting />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/globals",
    element: (
      <DomainProvider value={{ domainName: "barramento" }}>
        <ConfigsPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/globals/new",
    element: (
      <DomainProvider value={{ domainName: "barramento" }}>
        <Config />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4ubus/globals/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "barramento" }}>
        <Config />
      </DomainProvider>
    ),
  },

  {
    path: "settings/4ubus/globals/sync",
    element: (
      <DomainProvider value={{ domainName: "barramentosync" }}>
        <SyncPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/workGroupTypes",
    element: (
      <DomainProvider value={{ domainName: "ticketservicemessage" }}>
        <WorkGroupTypesPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/workGroupTypes/new",
    element: (
      <DomainProvider value={{ domainName: "ticketservicemessage" }}>
        <WorkGroupType />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/workGroupTypes/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketservicemessage" }}>
        <WorkGroupType />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/healthProgramTypes",
    element: (
      <DomainProvider value={{ domainName: "healthprogramtype" }}>
        <HealthProgramTypesPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/healthProgramTypes/new",
    element: (
      <DomainProvider value={{ domainName: "healthprogramtype" }}>
        <HealthProgramType />
      </DomainProvider>
    ),
  },
  {
    path: "settings/hcp/healthProgramTypes/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "healthprogramtype" }}>
        <HealthProgramType />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/checkIn",
    element: (
      <DomainProvider value={{ domainName: "tasyschedconfirmationrule" }}>
        <ScheduleCheckInPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/checkIn/new",
    element: (
      <DomainProvider value={{ domainName: "tasyschedconfirmationrule" }}>
        <ScheduleCheckIn />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/checkIn/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "tasyschedconfirmationrule" }}>
        <ScheduleCheckIn />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/confirmation",
    element: (
      <DomainProvider value={{ domainName: "tasyschedconfirmationrule" }}>
        <ScheduleConfirmationPage />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/confirmation/new",
    element: (
      <DomainProvider
        value={{ domainName: "tasyschedconfirmationrule" }}
        actions={["Create"]}
      >
        <ScheduleConfirmation />
      </DomainProvider>
    ),
  },
  {
    path: "settings/4uSched/tasyCalendar/confirmation/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "tasyschedconfirmationrule" }}>
        <ScheduleConfirmation />
      </DomainProvider>
    ),
  },
  {
    path: "evaluation/questions",
    element: (
      <DomainProvider value={{ domainName: "question" }}>
        <QuestionsPage />
      </DomainProvider>
    ),
  },
  {
    path: "evaluation/questions/new",
    element: (
      <DomainProvider value={{ domainName: "question" }} actions={["Create"]}>
        <Question />
      </DomainProvider>
    ),
  },
  {
    path: "evaluation/questions/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "question" }}>
        <Question />
      </DomainProvider>
    ),
  },
  {
    path: "logs/healthcheck",
    element: (
      <DomainProvider value={{ domainName: "healthcheckevent" }}>
        <HealthChecksLogsPage />
      </DomainProvider>
    ),
  },
  {
    path: "logs/logRegisterError",
    element: (
      <DomainProvider value={{ domainName: "logregistererror" }}>
        <LogRegisterErrors />
      </DomainProvider>
    ),
  },
  
  
  {
    path: "evaluation/evaluationForms",
    element: (
      <DomainProvider value={{ domainName: "evaluationforms" }}>
        <FormsPage />
      </DomainProvider>
    ),
  },
  {
    path: "evaluation/evaluationForms/new",
    element: (
      <DomainProvider
        value={{ domainName: "evaluationforms" }}
        actions={["Create"]}
      >
        <Form />
      </DomainProvider>
    ),
  },
  {
    path: "evaluation/evaluationForms/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "evaluationforms" }}>
        <Form />
      </DomainProvider>
    ),
  },
];

export default dashboardRoutes;
