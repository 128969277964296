import { Grid, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormInput,
  Information,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

import {
  createScheduleCheckIn,
  getScheduleCheckInById,
  updateScheduleCheckInById,
} from "../../../../../../../services/scheduleCheckIn.service";
import {
  TScheduleCheckInForm,
  scheduleCheckInSchema,
} from "./ScheduleCheckInSchema";
import { useCallback } from "react";

const translationPath = "page.settings.schedule.scheduleCheckIn.";

const ScheduleCheckIn = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createScheduleCheckIn
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateScheduleCheckInById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getScheduleCheckInById
  );

  const handleSave = (v: TScheduleCheckInForm) => {
    return save({
      time: Number(v.time),
      metersApproximation: Number(v.metersApproximation),
    });
  };

  const updateHandler = (v: TScheduleCheckInForm, id: string) =>
    update({
      item: {
        id: v.id,
        time: Number(v.time),
        metersApproximation: Number(v.metersApproximation),
      },
      id: id,
    });

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TScheduleCheckInForm>> => {
      const { data } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            time: data.time.toString(),
            metersApproximation: data.metersApproximation.toString(),
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TScheduleCheckInForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "schedule_check_in")}
      schema={scheduleCheckInSchema}
      subtitle={t(translationPath + "new_schedule_check_in")}
      subtitleWatchField={"metersApproximation"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Information info={t(translationPath + "help_meters")}>
              <FormInput
                size="small"
                name="metersApproximation"
                fullWidth
                label={
                  t(translationPath + "meters_approximation") ||
                  "Approximation in meters"
                }
                autoFocus
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t(translationPath + "meters")}
                    </InputAdornment>
                  ),
                }}
              />
            </Information>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Information info={t(translationPath + "help_time")}>
              <FormInput
                size="small"
                name="time"
                fullWidth
                label={t(translationPath + "time") || "Time"}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t(translationPath + "min")}
                    </InputAdornment>
                  ),
                }}
              />
            </Information>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ScheduleCheckIn;
