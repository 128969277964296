import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";

import { useWatch } from "react-hook-form";

interface IContactValueProps {
  index: number;
}

const ContactValue: React.FC<IContactValueProps> = ({ index }) => {
  const { control } = useFormContext();

  const type = useWatch({
    name: `contacts.${index}.contactType.code`,
    control,
  });

  return (
    <Grid item xs={12} sm={8}>
      <FormInput
        size="small"
        name={`contacts.${index}.value`}
        fullWidth
        mask={type === "1" || type === "2" ? "(00) 00000000[0]" : undefined}
      />
    </Grid>
  );
};

export default ContactValue;
