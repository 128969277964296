import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { IIpmTasyLogs } from "../../../../models/ipm-tasy-logs";
import { Chip, Stack, TextField } from "@mui/material";
import { NoPaginationApi } from "../../../../services/noPaginationApi.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useMemo, useState } from "react";
import {
  Modal,
  OverflowTooltip,
  TableNoPagination,
  UsePrivileges,
} from "@4uhub/lib4uhub";
import { format } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";

import TocIcon from "@mui/icons-material/Toc";
import IpmTasyModal from "./IpmTasyModal";

import FiltersActions from "../../../../components/FiltersActions/FiltersActions";

const IPM_X_TASY_ROUTE =
  process.env.REACT_APP_4UBUS +
  "/api/v1/TasyIPMPatientEncounterLog/ListIPMTasy";

const ipmTasyService = new NoPaginationApi<IIpmTasyLogs>(IPM_X_TASY_ROUTE);

const translationPath = "page.4uBus.logs.ipmXTasy.";

const IpmTasyLogsPage = () => {
  const [update, setUpdate] = useState(0);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [status, setStatus] = useState("");

  const [tasyIndividualIdentifier, setTasyIndividualIdentifier] = useState("");

  const [searchServiceNumber, setSearchServiceNumber] = useState("");

  const [open, setOpen] = useState(false);

  const [currentLog, setCurrentLog] = useState<IIpmTasyLogs>();

  const [filterParams, setFilterParams] = useState<
    Record<string, any> | undefined
  >();

  const { privileges } = UsePrivileges();

  const permission = !!privileges.find(
    (priv) =>
      priv.domainName === "all" || priv.domainName === "4ubuslogsipmfull"
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IIpmTasyLogs>[] = [
    {
      field: "status",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 130,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "messageStatus",
      headerName: t(translationPath + "message_status") || "Message status",
      maxWidth: 180,
      renderCell: (params) => {
        return params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="subtitle2"
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "tasyIndividualIdentifier",
      headerName:
        t(translationPath + "tasy_individual_identifier") || "Individual code",
      maxWidth: 100,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "systemOriginCode",
      headerName:
        t(translationPath + "service_number") || "Service number (IPM)",
      maxWidth: 220,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "tasyPatientEncounterNumber",
      headerName:
        t(translationPath + "service_number_tasy") || "Service number (Tasy)",
      maxWidth: 230,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "ipmPatientEmrLabExamResultCount",
      headerName: t(translationPath + "lab_exam_quantity") || "Qty Exam Lab",
      maxWidth: 110,
      renderCell: (params) => {
        return params.value ? params.value : 0;
      },
    },
    {
      field: "ipmPatientEmrNonLaboratoryTestCount",
      headerName: t(translationPath + "exam_nlab") || "Exam NLab",
      maxWidth: 100,
      renderCell: (params) => {
        return params.value ? params.value : 0;
      },
    },
    {
      field: "ipmPatientMedicineInUseCount",
      headerName: t(translationPath + "medicines") || "Medicines",
      maxWidth: 115,
      renderCell: (params) => {
        return params.value ? params.value : 0;
      },
    },
    {
      field: "ipmPatientAllergyCount",
      headerName: t(translationPath + "allergy") || "Allergy",
      maxWidth: 80,
      renderCell: (params) => {
        return params.value ? params.value : 0;
      },
    },
    {
      field: "hasPatientClinicalEvolution",
      headerName: t(translationPath + "has_evolution") || "Has evolution",
      maxWidth: 130,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "evolution")
                : t(translationPath + "no_evolution")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const filterHandler = () => {
    setFilterParams({
      Status: status.length !== 0 ? status : undefined,
      SystemOriginCode:
        searchServiceNumber.length !== 0 ? searchServiceNumber : undefined,
      Date: selectedDate
        ? selectedDate.toISOString().substring(0, 10)
        : undefined,
      TasyIndividualIdentifier:
        tasyIndividualIdentifier.length !== 0
          ? tasyIndividualIdentifier
          : undefined,
    });
  };

  const cleanFilterHandler = () => {
    setSearchServiceNumber("");
    setStatus("");
    setTasyIndividualIdentifier("");
    setSelectedDate(new Date());
    setFilterParams(undefined);
  };

  const closeModalHandler = () => setOpen(false);
  const openModalHandler = (params: GridRowParams<IIpmTasyLogs>) => {
    setCurrentLog(params.row);
    setOpen(true);
  };

  const props = useMemo(() => {
    const startDate = new Date().setHours(15);

    return {
      Date: new Date(startDate).toISOString().substring(0, 10),
      Update: update ? undefined : undefined,
    };
  }, [update]);

  const updateHandler = () => {
    setUpdate((oldState) => oldState + 1);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModalHandler}
        title={`${t(translationPath + "logs")}`}
      >
        {currentLog && <IpmTasyModal id={currentLog.id} />}
      </Modal>
      <TableNoPagination<IIpmTasyLogs>
        title={t(translationPath + "ipmxtasy")}
        columns={columns}
        service={ipmTasyService}
        onRowClick={() => {}}
        showDefaultMacroFilters={false}
        showRowActions={permission}
        headerElements={
          <img
            src="/LGPD.png"
            width={80}
            height={"auto"}
            alt="LPGD guaranteed"
          />
        }
        serviceProps={filterParams ? filterParams : props}
        rowActions={() => {
          return [
            {
              text: t(translationPath + "view_logs"),
              action: openModalHandler,
              icon: <TocIcon />,
            },
          ];
        }}
        macroFilters={() => {
          return (
            <Stack flexDirection={"row"} gap={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={selectedDate}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                    field: {
                      clearable: true,
                      onClear: () => setSelectedDate(null),
                    },
                  }}
                  onChange={(v: any) => {
                    if (v) {
                      const newValue = new Date(v).setHours(15);
                      setSelectedDate(new Date(newValue));
                    } else {
                      setSelectedDate(null);
                    }
                  }}
                  format={"dd/MM/yyyy"}
                />
              </LocalizationProvider>
              <TextField
                value={status}
                size="small"
                label={t(translationPath + "search")}
                fullWidth
                onChange={(e) => setStatus(e.target.value)}
              />
              <TextField
                value={searchServiceNumber}
                size="small"
                label={t(translationPath + "service_number_search")}
                fullWidth
                onChange={(e) => setSearchServiceNumber(e.target.value)}
              />
              <TextField
                value={tasyIndividualIdentifier}
                size="small"
                label={t(translationPath + "tasy_individual_identifier")}
                fullWidth
                onChange={(e) => setTasyIndividualIdentifier(e.target.value)}
              />
              <FiltersActions
                filterHandler={filterHandler}
                cleanFilterHandler={cleanFilterHandler}
                refreshHandler={updateHandler}
                disableFilterBtn={
                  status.length === 0 &&
                  searchServiceNumber.length === 0 &&
                  selectedDate?.toLocaleDateString() ===
                    new Date().toLocaleDateString()
                }
                showCleanBtn={!!filterParams}
              />
            </Stack>
          );
        }}
      />
    </>
  );
};

export default IpmTasyLogsPage;
