import { ISelectType, RadioGroupInput, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScheduleAppointmentService } from "../../../../../Schedule.service";


const Type = () => {
  const [types, setTypes] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(ScheduleAppointmentService.getTypes);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (data && success) {
      setTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <RadioGroupInput
      name="search.type"
      groupLabel={t("components.scheduleAssistant.newSchedule.search.type")}
      disableBorder
      radios={types.map((i) => ({
        label: i.name,
        value: i.code!,
      }))}
    />
  );
};

export default Type;
