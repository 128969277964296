import "./Editor.css";

import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { Box } from "@mui/material";
import MenuBar from "./MenuBar";
import { FontSize } from "./extensions/fontSize";
import { IMenuBarProps } from "./models";

interface IEditorComponent {
  onChange?: (value: string) => any;
  value?: string;
  error?: boolean;
  readOnly?: boolean;
  maxHeight?: string;
  menuBarProps?: IMenuBarProps;
}

const EditorComponent: React.FC<IEditorComponent> = ({
  onChange,
  value = "",
  error = false,
  readOnly = false,
  maxHeight = "600px",
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
}) => {
  const editor = useEditor({
    extensions: [
      Color,
      TextStyle,
      FontSize.configure({
        initialFontSize: "12",
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
    ],
    content: value ? value : "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      if (onChange) {
        onChange(html);
      }
    },
    editable: !readOnly,
  });

  return (
    <Box
      sx={(theme) => ({
        border: "1px solid",
        borderRadius: theme.shape.borderRadius - 7,
        borderColor: error ? theme.palette.error.main : theme.palette.divider,
        overflow: "auto",
        maxHeight: maxHeight,
        position: "relative",
      })}
    >
      {!readOnly && (
        <Box
          padding={2}
          paddingBottom={0}
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: theme.palette.background.default,
            paddingBottom: "10px",
            borderBottom: "1px solid ",
            borderBottomColor: theme.palette.divider,
          })}
        >
          <MenuBar editor={editor} menuBarProps={menuBarProps} />
        </Box>
      )}

      <Box padding={2}>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};

export default EditorComponent;
