import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TTicketAutoFinishRuleForm } from "./TicketAutoFinishRuleSchema";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

const Message = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues } =
    useFormContext<TTicketAutoFinishRuleForm>();

  const finish = useWatch({
    name: "finish",
    control,
  });

  useEffect(() => {
    if (finish) {
      setValue(
        "message",
        getValues("message")
          ? getValues("message")
          : "Seu atendimento foi finalizado, se precisar de alguma informação informe aos nossos atendentes o número de protocolo {{protocolNumber}}."
      );
    } else {
      setValue("message", "");
    }
  }, [setValue, getValues, finish]);

  return (
    <>
      {finish && (
        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="message"
            fullWidth
            label={t(translationPath + "message") || "Message"}
            multiline
            minRows={4}
          />
          <Typography variant="body2">
            {t(translationPath + "message_helper")}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Message;
