import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";
import { DomainProvider } from "@4uhub/lib4uhub";
import WhatsappPage from "../pages/4uChatbot/Templates/Whatsapp/WhatsappPage";
import Whatsapp from "../pages/4uChatbot/Templates/Whatsapp/WhatsappForm/Whatsapp";
import AutomationRulesPage from "../pages/4uChatbot/Automations/AutomationRules/AutomationRulesPage";
import AutomationRule from "../pages/4uChatbot/Automations/AutomationRules/AutomationRuleForm/AutomationRule";

const chatBotRoutes: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "templates/whatsapp",
    element: (
      <DomainProvider value={{ domainName: "ticketautomationtemplates" }}>
        <WhatsappPage />
      </DomainProvider>
    ),
  },
  {
    path: "templates/whatsapp/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketautomationtemplates" }}
        actions={["Create"]}
      >
        <Whatsapp />
      </DomainProvider>
    ),
  },
  {
    path: "templates/whatsapp/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketautomationtemplates" }}>
        <Whatsapp />
      </DomainProvider>
    ),
  },
  {
    path: "createAutomations/automationRules",
    element: (
      <DomainProvider value={{ domainName: "ticketautomationrule" }}>
        <AutomationRulesPage />
      </DomainProvider>
    ),
  },
  {
    path: "createAutomations/automationRules/new",
    element: (
      <DomainProvider
        value={{ domainName: "ticketautomationrule" }}
        actions={["Create"]}
      >
        <AutomationRule />
      </DomainProvider>
    ),
  },
  {
    path: "createAutomations/automationRules/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "ticketautomationrule" }}>
        <AutomationRule />
      </DomainProvider>
    ),
  },
];

export default chatBotRoutes;
