import { GlobalAxios } from "../axios/axios-config";
import { IIntegratedGenerics } from "../models/generics";

const BUS_GENERIC_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/Generic";

export const syncGenericNow = () => {
  return GlobalAxios.post(BUS_GENERIC_ROUTE + "/Sync/Now");
};

export const getIntegratedGenerics = () => {
  return GlobalAxios.get<IIntegratedGenerics>(BUS_GENERIC_ROUTE + "/List");
};
