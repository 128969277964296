import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import DynamicIcon from "../../../../../../../components/UI/DynamicIcon";
import { ScheduleAppointmentService } from "../../../../../Schedule.service";
import { IItem } from "../../../../../../../components/SelectIcon/model";
import SelectIconForm from "../../../../../../../components/SelectIcon/SelectIconForm";

const getIcon = (
  code: string | number,
  size: number,
  color: string
): JSX.Element => {
  switch (code) {
    case "0":
      return (
        <DynamicIcon
          icon={"WeatherSunsetUp"}
          color="inherit"
          sx={{ fontSize: size, color: color }}
        />
      );
    case "1":
      return (
        <DynamicIcon
          icon={"WeatherSunsetDown"}
          color="inherit"
          sx={{ fontSize: size, color: color }}
        />
      );

    default:
      return (
        <DynamicIcon
          icon={"WeatherNight"}
          color="inherit"
          sx={{ fontSize: size, color: "red" }}
        />
      );
  }
};

const Period = () => {
  const [periods, setPeriods] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(ScheduleAppointmentService.getPeriodOptions);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setPeriods(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const optionsWithIcon: IItem[] = useMemo(
    () => periods.map((p) => ({ ...p, icon: getIcon(p.code!, 25, "white") })),
    [periods]
  );

  return (
    <SelectIconForm
      options={optionsWithIcon}
      name="search.period"
      multi
      label={t("components.scheduleAssistant.newSchedule.search.period")!}
    />
  );
};

export default memo(Period);
