import { Modal } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";

import NewSchedule from "./NewSchedule/NewSchedule";
import { useSchedule } from "../../ScheduleProvider";

interface IScheduleAssistantProps {
  open: boolean;
  onClose: () => void;
}

const ScheduleAssistant: React.FC<IScheduleAssistantProps> = ({
  onClose,
  open,
}) => {
  const { fetch } = useSchedule();

  const onFinishHandler = useCallback(() => {
    onClose();
    fetch();
  }, [onClose, fetch]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxDialogWidth="md"
      disableBackdropClick
    >
      <NewSchedule onFinishStepper={onFinishHandler} />
    </Modal>
  );
};

export default memo(ScheduleAssistant);
