import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { GlobalAxios } from "../../../../axios/axios-config";
import { IFileValueProps } from "./models";
import { InputFileForm } from "@4uhub/lib4uhub";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

const ContentsFileValue: React.FC<IFileValueProps> = ({
  index,
  onFileSelected,
  mediaTypeCode = "1",
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <InputFileForm
        name={`contentMediaFiles.${index}.file`}
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        fileInputProps={{
          accept: ".jpg, ,jpeg, .png, .webp",
          label: `${t("page.register.extensions.file")}`,
          maxFileSize: 2,
          mediaTypeCode: mediaTypeCode,
          mediaTypeIdentifier: "ContentType",
          parentName: "ContentConfig",
          type: "Public",
          onFileSelected: onFileSelected,
          inputProps: {
            size: "small",
          },
        }}
      />
    </Grid>
  );
};

export default ContentsFileValue;
