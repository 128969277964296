import { Tooltip, Typography } from "@mui/material";
import { ICellDataProps } from "./models";
import { useEffect, useRef, useState } from "react";

const CellData = ({ value }: ICellDataProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    if (!textElementRef.current) return;
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={value} disableHoverListener={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: "capitalize",
        }}
      >
        <Typography variant="body2">{value}</Typography>
      </div>
    </Tooltip>
  );
};

export default CellData;
