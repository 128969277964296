import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import useJobs from "../../../hooks/useJobs";
import { Button } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const translationPath = "page.jobs.";

const JobsPage = () => {
  const { openJobs } = useJobs();
  const { t } = useTranslation();

  useEffect(() => {
    openJobs();
  }, [openJobs]);

  return (
    <Stack
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={4}
    >
      <Stack textAlign={"center"}>
        <Typography variant="h5">{t(translationPath + "title")}</Typography>
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "subtitle")}
        </Typography>
      </Stack>
      <Button onClick={() => openJobs()} variant="contained">
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default JobsPage;
