import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";
import AccessTokenPage from "../pages/4uBus/Register/AccessToken/AccessTokenPage";
import AccessToken from "../pages/4uBus/Register/AccessToken/AccessTokenForm/AccessToken";
import { DomainProvider } from "@4uhub/lib4uhub";
import IpmTasyPage from "../pages/4uBus/Relationships/IpmTasy/IpmTasyPage";
import IpmTasy from "../pages/4uBus/Relationships/IpmTasy/IpmTasyForm/IpmTasy";
import SchedulesPage from "../pages/4uBus/Restrictions/Schedules/SchedulesPage";
import Schedule from "../pages/4uBus/Restrictions/Schedules/Schedule/Schedule";
import IpmTasyLogsPage from "../pages/4uBus/Logs/IpmTasy/IpmTasyLogsPage";
import TasyIpmLogsPage from "../pages/4uBus/Logs/TasyIpm/TasyIpmLogsPage";
import ProfileTasyBusPage from "../pages/4uBus/Register/ProfileTasyBus/ProfileTasyBusPage";
import ProfileTasyBus from "../pages/4uBus/Register/ProfileTasyBus/ProfileTasyBusForm/ProfileTasyBus";
import RestrictionRule from "../pages/4uBus/Register/ProfileTasyBus/RestrictionRule/RestrictionRuleForm/RestrictionRule";
import TasyCalendarsLogsPage from "../pages/4uBus/Logs/TasyCalendars/TasyCalendarsLogsPage";

const bus: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "register/accessTokens",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <AccessTokenPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/accessTokens/new",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }} actions={["Create"]}>
        <AccessToken />
      </DomainProvider>
    ),
  },
  {
    path: "register/accessTokens/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <AccessToken />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <ProfileTasyBusPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy/new",
    element: (
      <DomainProvider
        value={{ domainName: "restrictionruleprofile" }}
        actions={["Create"]}
      >
        <ProfileTasyBus />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <ProfileTasyBus />
      </DomainProvider>
    ),
  },
  {
    path: "register/restrictionRule/:profileTasyId/:extensionCode/new",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <RestrictionRule />
      </DomainProvider>
    ),
  },
  {
    path: "register/restrictionRule/:profileTasyId/:extensionCode/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <RestrictionRule />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <IpmTasyPage />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension/new",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <IpmTasy />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <IpmTasy />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension",
    element: (
      <DomainProvider value={{ domainName: "restrictionrule" }}>
        <SchedulesPage />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension/new",
    element: (
      <DomainProvider
        value={{ domainName: "restrictionrule" }}
        actions={["Create"]}
      >
        <Schedule />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionrule" }}>
        <Schedule />
      </DomainProvider>
    ),
  },
  {
    path: "logs/ipmxtasy",
    element: (
      <DomainProvider value={{ domainName: "4ubuslogsipm" }}>
        <IpmTasyLogsPage />
      </DomainProvider>
    ),
  },
  {
    path: "logs/tasyxipm",
    element: (
      <DomainProvider value={{ domainName: "4ubuslogsipm" }}>
        <TasyIpmLogsPage />
      </DomainProvider>
    ),
  },
  {
    path: "logs/tasyCalendar",
    element: (
      <DomainProvider value={{ domainName: "forubuscchedctrllogs" }}>
        <TasyCalendarsLogsPage />
      </DomainProvider>
    ),
  },
];

export default bus;
