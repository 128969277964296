import { IconButton, Stack, Tooltip } from "@mui/material";
import { IFiltersActionsProps } from "./models";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useTranslation } from "react-i18next";

import CachedIcon from "@mui/icons-material/Cached";

const translationPath = "components.filtersActions.";

const FiltersActions = ({
  filterHandler,
  cleanFilterHandler,
  disableFilterBtn,
  showCleanBtn,
  refreshHandler,
}: IFiltersActionsProps) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"row"}>
      {(refreshHandler || filterHandler) && showCleanBtn !== undefined && (
        <Tooltip title={t(translationPath + "refresh")}>
          <IconButton onClick={showCleanBtn ? filterHandler : refreshHandler}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
      )}
      {filterHandler && (
        <Tooltip title={t(translationPath + "filter")}>
          <div>
            <IconButton onClick={filterHandler} disabled={disableFilterBtn}>
              <FilterAltIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {showCleanBtn && cleanFilterHandler && (
        <Tooltip title={t(translationPath + "clean")}>
          <IconButton onClick={cleanFilterHandler}>
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default FiltersActions;
