import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Ref, memo, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import TodayButton from "./TodayButton";
import ControlButtons from "./ControlButtons";
import ActualDate from "./ActualDate";
import ModeSelector from "./ModeSelector";
import { useSchedule } from "../../ScheduleProvider";
import ScheduleAssistant from "../ScheduleAssistant/ScheduleAssistant";
import AssistentButton from "./AssistentButton";

interface HeaderProps {
  headerRef: Ref<HTMLDivElement>;
}

const Header: React.FC<HeaderProps> = ({ headerRef }) => {
  const { setOpen } = useSchedule();

  const [openAssistent, setOpenAssistent] = useState(false);

  const { breakpoints } = useTheme();

  const downMd = useMediaQuery(breakpoints.down("md"));

  const onFilterClick = () => {
    setOpen(true);
  };

  const onAssistentHandler = () => {
    setOpenAssistent(true);
  };

  const onCloseAssistent = () => {
    setOpenAssistent(false);
  };

  return (
    <>
      <ScheduleAssistant onClose={onCloseAssistent} open={openAssistent} />
      <Grid
        container
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        ref={headerRef}
        sx={(t) => ({
          borderBottom: `1px solid ${t.palette.divider}`,
          paddingBottom: t.spacing(3),
          paddingX: t.spacing(3),
          boxSizing: "border-box",
        })}
      >
        <Grid item>
          <Grid container gap={4} flexDirection={"row"} alignItems={"center"}>
            <TodayButton />
            <ControlButtons />
            <ActualDate />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container gap={2}>
            <AssistentButton onClick={onAssistentHandler} />
            {downMd && (
              <IconButton onClick={onFilterClick}>
                <FilterAltIcon />
              </IconButton>
            )}
            <ModeSelector />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(Header);
