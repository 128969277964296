import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table, useFetch } from "@4uhub/lib4uhub";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useCallback } from "react";
import { ITicketsService } from "../../../../../models/tickets-service";
import TicketServWhatsService from "../../../../../services/ticketServiceWhats.service";

const SITE_APP_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const siteAppService = new MainApiService<ITicketsService>(SITE_APP_ROUTE);

const ticketServWhatsService = new TicketServWhatsService();

const translationPath = "chatBot.page.register.ticketService.";

const SiteAppPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const { sendRequest, loading } = useFetch(
    ticketServWhatsService.getWhatsConfig
  );

  const columns: GridColDef<ITicketsService>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "ticketServiceType",
      headerName:
        t(translationPath + "ticket_service_type") || "Ticket Service Type",
      renderCell: (params) => {
        return params.value.name;
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<ITicketsService>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const whatsappSettings = useCallback(
    async (ticketServiceId: string) => {
      const { data, success } = await sendRequest(ticketServiceId);
      if (data && success) {
        if (!data.id) {
          navigate(`whatsappConfig/${ticketServiceId}/new`);
        }
        if (data.id) {
          navigate(`whatsappConfig/${ticketServiceId}/${data.id}/edit`);
        }
      }
    },
    [sendRequest, navigate]
  );

  return (
    <Table<ITicketsService>
      showDefaultMacroFilters={false}
      service={siteAppService}
      columns={columns}
      title={t(translationPath + "ticket_service")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      showDeleteButton={false}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      syncing={loading}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      rowActions={(params) => {
        if (params.row.ticketServiceType.code === "1") {
          return [
            {
              action: (params) => whatsappSettings(params.row.id),
              text: t(translationPath + "whats_settings"),
              icon: <WhatsAppIcon fontSize="small" />,
            },
          ];
        }
        return [];
      }}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a ticket service"
      }
      addButtonLabel={
        t(translationPath + "add_ticket_service") || "Add Ticket Service"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default SiteAppPage;
