import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import { getDayInfo } from "../utils";
import { ScheduleAppointmentService } from "../Schedule.service";
import { EStatusAvailability, IAvaiableDate, IFilter } from "../model";
import { endOfWeek, lastDayOfMonth, startOfMonth, startOfWeek } from "date-fns";
import useDate from "../../../hooks/useDate";

const RenderDay = (
  props: PickersDayProps<Date> & {
    avaiableDates?: IAvaiableDate[];
    fullDayColor?: string;
    someAvaiableColor?: string;
    allAvaiableColor?: string;
  }
) => {
  const {
    fullDayColor,
    someAvaiableColor,
    allAvaiableColor,
    day,
    outsideCurrentMonth,
    avaiableDates,
    selected,
    ...other
  } = props;

  const date = getDayInfo(day, avaiableDates);

  const backgroundColor = useMemo(() => {
    if (date?.statusAvailability === EStatusAvailability.NON_AVAIABLE) {
      return fullDayColor;
    }

    if (date?.statusAvailability === EStatusAvailability.EXISTS_AVAIABLE) {
      return someAvaiableColor;
    }

    if (date?.statusAvailability === EStatusAvailability.ALL_AVAIABLE) {
      return allAvaiableColor;
    }

    return "transparent";
  }, [date, allAvaiableColor, someAvaiableColor, fullDayColor]);

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={selected}
      style={{ backgroundColor: selected ? undefined : backgroundColor }}
    />
  );
};

interface ICalendarProps {
  value: Date;
  onDateChange: (date: Date) => void;
  filter: IFilter;
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
}

const Calendar: React.FC<ICalendarProps> = ({
  onDateChange,
  value,
  allAvaiableColor,
  fullDayColor,
  someAvaiableColor,
  filter: { professionals, exams, period, situation, speciality },
}) => {
  const [avaiableDates, setAvaiableDates] = useState<IAvaiableDate[]>([]);

  const { locale } = useDate();

  const [month, setMonth] = useState<Date>(value);

  const { sendRequest, loading } = useFetch(
    ScheduleAppointmentService.CalendarAllConsultationExamAvailabilityByDate
  );

  const startDate = useMemo(
    () => startOfWeek(startOfMonth(month)).toDateString(),
    [month]
  );

  const endDate = useMemo(
    () => endOfWeek(lastDayOfMonth(month)).toDateString(),
    [month]
  );

  const calendarIdentifierList = useMemo(
    () => professionals.concat(exams).map((c) => Number(c)),
    [exams, professionals]
  );

  const filterValue = useMemo(() => {
    if (calendarIdentifierList.length === 0) {
      return;
    }

    return {
      CalendarDateStart: startDate,
      CalendarDateEnd: endDate,
      MedicalSpecialtyCode: speciality ?? "",
      CalendarIdentifierList: calendarIdentifierList,
      ScheduleShiftCode: period === "-1" ? null : period,
      SituationCalendar: situation,
    };
  }, [
    speciality,
    period,
    situation,
    calendarIdentifierList,
    startDate,
    endDate,
  ]);

  const fetch = useCallback(async () => {
    if (!filterValue) return;
    const { data, success } = await sendRequest(filterValue);

    if (data && success) {
      setAvaiableDates(data);
    }
  }, [filterValue, sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DateCalendar
        value={value}
        fixedWeekNumber={6}
        showDaysOutsideCurrentMonth
        loading={loading}
        renderLoading={() => <DayCalendarSkeleton />}
        onMonthChange={setMonth}
        onChange={(newValue) => newValue && onDateChange(newValue)}
        slots={{
          day: RenderDay,
        }}
        slotProps={{
          day: {
            avaiableDates,
            fullDayColor,
            someAvaiableColor,
            allAvaiableColor,
          } as any,
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(Calendar);
