import { z } from "zod";

const translationPath = "chatBot.page.register.ticketService.errors.";

const ticketServiceTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_service_type" }
);

const siteAppExtensionsSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "site_app_extensions" }
);

const ticketChannelInitialSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_channel_initial" }
);

const ticketMessageReceivedEventSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const ticketServiceSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  showMessageDisableArea: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  ticketServiceType: ticketServiceTypeSchema,
  siteAppExtensions: z
    .array(siteAppExtensionsSchema)
    .refine((val) => val.length !== 0, translationPath + "site_app_extensions"),
  ticketChannelInitial: ticketChannelInitialSchema,
  ticketMessageReceivedEvent: ticketMessageReceivedEventSchema.nullish(),
});

export type TTicketServiceForm = z.infer<typeof ticketServiceSchema>;
