import { Stack } from "@mui/material";
import { memo, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateInput, useFormContext } from "@4uhub/lib4uhub";

import Type from "./Type";
import Speciality from "./Speciality";
import Professional from "./Professional";
import Period from "./Period";
import { TAppointment } from "../../schema";
import ExamGroup from "./ExamGroup";
import { ETasyScheduleTypeCode } from "../../../../../model";

const SearchAvailability = () => {
  const methods = useFormContext<TAppointment>();

  const { control } = methods;

  const type = useWatch({ control, name: "search.type" });

  const { t } = useTranslation();

  const isExam = useMemo(() => type === ETasyScheduleTypeCode.EXAM, [type]);

  return (
    <Stack gap={1}>
      <Type />

      {!isExam && (
        <>
          <Speciality />
          <Professional />
        </>
      )}

      {isExam && <ExamGroup />}

      <DateInput
        name="search.date"
        label={t("components.scheduleAssistant.newSchedule.search.from")}
      />
      <Period />
    </Stack>
  );
};

export default memo(SearchAvailability);
