import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { isSameDay } from "date-fns";

export const Day = (
  props: PickersDayProps<Date> & { highlightedDays?: Date[] }
) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted =
    highlightedDays.some((d) => isSameDay(day, d)) && !outsideCurrentMonth;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={(t) => ({
        border: isHighlighted
          ? `1px solid ${t.palette.primary.main}`
          : undefined,
      })}
      disabled={!isHighlighted}
    />
  );
};
