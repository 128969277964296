import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";

import {
  createHealthCheck,
  getHealthCheckById,
  updateHealthCheckById,
} from "../../../../../../services/healthChecks.service";
import { THealthCheckForm, healthCheckSchema } from "./HealthCheckSchema";
import EstablishmentsService from "../../../../../../services/establishments.service";
import { useCallback, useEffect, useState } from "react";

const establishmentListService = new EstablishmentsService();

const translationPath = "page.settings.generalSettings.healthChecks.";

const HealthCheck = () => {
  const { t } = useTranslation();

  const [establishmentsOptions, setEstablishmentsOptions] = useState<
    ISelectType[]
  >([]);

  const { sendRequest } = useFetch(establishmentListService.masterList);

  const { sendRequest: save, loading: sLoading } = useFetch(createHealthCheck);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateHealthCheckById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(getHealthCheckById);

  const handleSave = (v: THealthCheckForm) => {
    return save({
      name: v.name,
      payload: v.payload,
      restoredPayload: v.restoredPayload,
      uri: v.uri,
      webhookURL: v.webhookURL,
      establishmentId: v.establishment ? v.establishment.id : undefined,
    });
  };

  const updateHandler = (v: THealthCheckForm, id: string) =>
    update({
      item: {
        id: v.id,
        name: v.name,
        payload: v.payload,
        restoredPayload: v.restoredPayload,
        uri: v.uri,
        webhookURL: v.webhookURL,
        establishmentId: v.establishment ? v.establishment.id : undefined,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  const fetchEstablishments = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setEstablishmentsOptions(data.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchEstablishments();
  }, [fetchEstablishments]);

  return (
    <FormContainer<THealthCheckForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "health_check")}
      schema={healthCheckSchema}
      subtitle={t(translationPath + "new_health_check")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="uri"
              fullWidth
              label={t(translationPath + "uri") || "URI"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="payload"
              fullWidth
              label={t(translationPath + "payload") || "Payload"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="restoredPayload"
              fullWidth
              label={
                t(translationPath + "restored_payload") || "Restored Payload"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="webhookURL"
              fullWidth
              label={t(translationPath + "webhook_url") || "Webhook URL"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "establishment")}
              name="establishment"
              options={establishmentsOptions}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HealthCheck;
