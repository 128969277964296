import { Controller } from "react-hook-form";
import { Box, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditorComponent from "./Editor";
import { useFormContext } from "@4uhub/lib4uhub";
import { IMenuBarProps } from "./models";

interface IFormTextEditor {
  name: string;
  onlyCode?: boolean;
  menuBarProps?: IMenuBarProps;
}

const FormTextEditor: React.FC<IFormTextEditor> = ({
  name,
  onlyCode,
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box>
          <EditorComponent
            onChange={onChange}
            value={value}
            error={!!error}
            menuBarProps={menuBarProps}
          />
          <FormHelperText error>
            {error ? t(error?.message || "") : ""}
          </FormHelperText>
        </Box>
      )}
    />
  );
};

export default FormTextEditor;
