import { z } from "zod";

const translationPath = "page.4uHcp.hcp.healthProgramImports.errors.";

export const healthProgramImportSchema = z.object({
  id: z.string().optional(),
  importPersonName: z.string().min(1, translationPath + "import_person_name"),
  originOfDemand: z.string().optional(),
  file: z
    .instanceof(File, { message: translationPath + "file" })
    .refine((val) => val, translationPath + "file"),
});

export type THealthProgramImportForm = z.infer<
  typeof healthProgramImportSchema
>;
