import { z } from "zod";

const translationPath = "chatBot.page.automations.automationRules.errors.";

const ticketEventSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "ticket_event" }
);

const automationActionSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const ticketAutomationSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    automationAction: automationActionSchema,
    isSendFile: z.boolean(),
    allowTransfer: z.boolean(),
    allowForm: z.boolean(),
  },
  { invalid_type_error: translationPath + "ticket_automation" }
);

const ticketAutomationTemplateSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "ticket_automation_template" }
);

const ticketChannelStepAttendantSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const ticketChannelStepRobotSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "ticket_service" }
);

const ticketChannelAttendantSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const ticketChannelRobotSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

export const automationRuleSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    name: z.string().min(1, translationPath + "name"),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
    ticketEvent: ticketEventSchema.nullish(),
    ticketAutomation: ticketAutomationSchema,
    ticketAutomationTemplate: ticketAutomationTemplateSchema,
    ticketChannelStepAttendant: ticketChannelStepAttendantSchema.nullish(),
    ticketChannelStepRobot: ticketChannelStepRobotSchema.nullish(),
    ticketChannelAttendant: ticketChannelAttendantSchema.nullish(),
    ticketChannelRobot: ticketChannelRobotSchema.nullish(),
    ticketService: ticketServiceSchema,
  })
  .superRefine((val, ctx) => {
    if (!val.ticketEvent) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "ticket_event",
        path: ["ticketEvent"],
      });
    }
    if (val.ticketAutomation.allowTransfer) {
      if (!val.ticketChannelStepAttendant) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "ticket_channel_step_attendant",
          path: ["ticketChannelStepAttendant"],
        });
      }
    }
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_bigger",
        path: ["initialDate"],
      });
    }
    if (val.ticketAutomation.code !== "8") {
      if (!val.ticketChannelStepRobot) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "ticket_channel_step_robot",
          path: ["ticketChannelStepRobot"],
        });
      }
    }
  });

export type TAutomationRuleForm = z.infer<typeof automationRuleSchema>;
