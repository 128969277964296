import { z } from "zod";

const translationPath = "components.content.errors.";

const language = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

const youtubeLinkRegExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  
const contentVideo = z.object({
  name: z.string(),
  link: z.string().regex(youtubeLinkRegExp, translationPath + "link"),
});

const contentVersionSchema = z
  .object({
    id: z.string().optional().nullable(),
    language: language.nullable(),
    title: z.string().min(1, translationPath + "title"),
    contentText: z.string().min(1, translationPath + "content"),
    summary: z.string().min(1, translationPath + "summary"),
    contentTags: z.array(z.string()).min(1, translationPath + "tags"),
    contentVideos: z.array(contentVideo),
  })
  .superRefine((val, ctx) => {
    if (!val.language) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["language"],
        message: `${translationPath + "language"}`,
      });
    }
  });

const mediaFileSchema = z.object(
  {
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
  },
  { required_error: translationPath + "file" }
);

const captionSchema = z.object({
  language: z.string().optional().nullable(),
  caption: z.string().optional().nullable(),
});

const contentMediaFilesSchema = z
  .object({
    file: mediaFileSchema,
    captions: z.array(captionSchema),
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const contentSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  isPublished: z.boolean(),
  readingTime: z.string().min(1, translationPath + "reading_time"),
  publicationDate: z.date(),
  contents: z.array(contentVersionSchema).min(1, translationPath + "contents"),
  contentMediaFiles: z.array(contentMediaFilesSchema),
  siteApps: z.array(siteAppSchema).min(1, translationPath + "site_apps"),
});

export type TContentForm = z.infer<typeof contentSchema>;
