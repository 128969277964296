import { z } from "zod";

const translationPath = "page.register.banners.errors.";

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const mediaFileSchema = z.object(
  {
    mediaType: genericSchema.optional(),
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
  },
  { required_error: translationPath + "file" }
);

const siteAppMediaFilesSchema = z
  .object({
    file: mediaFileSchema,
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const languageSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "language" }
);

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const bannerLocalSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const bannerSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translationPath + "name"),
  title: z.string().min(1, translationPath + "title"),
  alternativeText: z.string().min(1, translationPath + "alternative_text"),
  displayOrder: z.string().min(1, translationPath + "display_order"),
  enable: z.boolean(),
  language: languageSchema,
  siteApps: z.array(siteAppSchema).min(1, translationPath + "site_apps"),
  bannerMediaFiles: z.array(siteAppMediaFilesSchema),
  bannerLocals: z
    .array(bannerLocalSchema)
    .min(1, translationPath + "banner_locals"),
});

export type TBannerForm = z.infer<typeof bannerSchema>;
