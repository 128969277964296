import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../../services/mainApi.service";
import TicketChannelStep from "./TicketChannelStep";
import {
  createTicketAutoFinishRule,
  getTicketAutoFinishRuleById,
  updateTicketAutoFinishRuleById,
} from "../../../../../../services/ticketAutoFinishRule.service";
import {
  TTicketAutoFinishRuleForm,
  ticketAutoFinishRuleSchema,
} from "./TicketAutoFinishRuleSchema";
import Message from "./Message";
import { useCallback, useState } from "react";
import TicketChannel from "./TicketChannel";
import TicketWorkspace from "./TicketWorkspace";

const TICKET_TYPE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const ticketTypeService = new MainApi(TICKET_TYPE_ROUTE);

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

const TicketAutoFinishRule = () => {
  const { t } = useTranslation();

  const [ticketWorkspaceId, setTicketWorkspaceId] = useState<string>("");

  const [message, setMessage] = useState("");

  const { sendRequest: save, loading: sLoading } = useFetch(
    createTicketAutoFinishRule
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateTicketAutoFinishRuleById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getTicketAutoFinishRuleById
  );

  const handleSave = (v: TTicketAutoFinishRuleForm) => {
    return save({
      ...v,
      ticketChannelId: v.ticketChannel ? v.ticketChannel.id : "",
      ticketChannelStepId: v.ticketChannelStep
        ? v.ticketChannelStep.id
        : undefined,
      ticketTypeId: v.ticketType.id,
      minutes: Number(v.minutes),
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
    });
  };

  const updateHandler = (v: TTicketAutoFinishRuleForm, id: string) =>
    update({
      item: {
        ...v,
        ticketChannelId: v.ticketChannel ? v.ticketChannel.id : "",
        ticketChannelStepId: v.ticketChannelStep
          ? v.ticketChannelStep.id
          : undefined,
        ticketTypeId: v.ticketType.id,
        minutes: Number(v.minutes),
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TTicketAutoFinishRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        if (data.ticketChannel && data.ticketChannel.ticketWorkspaceId) {
          setTicketWorkspaceId(data.ticketChannel.ticketWorkspaceId);
        }

        if (data.message && data.message.length !== 0) {
          setMessage(data.message);
        }

        const newData: TTicketAutoFinishRuleForm = {
          ...data,
          minutes: data.minutes.toString(),
          initialDate: new Date(data.initialDate),
          endDate: new Date(data.endDate),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketAutoFinishRuleForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "ticket_auto_finish_rule")}
      schema={ticketAutoFinishRuleSchema}
      subtitle={t(translationPath + "new_ticket_auto_finish_rule")}
      subtitleWatchField={"ticketType.name"}
      defaultValues={{
        finish: true,
        message:
          message.length !== 0
            ? message
            : "Seu atendimento foi finalizado, se precisar de alguma informação informe aos nossos atendentes o número de protocolo {{protocolNumber}}.",
      }}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <SwitchInput
              name="finish"
              label={t(translationPath + "finish_rule")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="minutes"
              type="number"
              fullWidth
              label={t(translationPath + "minutes") || "Minutes"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ identifier: "TICKET_TYPE" }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "ticket_type")}
              name="ticketType"
              request={ticketTypeService.list}
            />
          </Grid>

          <TicketWorkspace ticketWorkspaceId={ticketWorkspaceId} />

          <TicketChannel />

          <TicketChannelStep />

          <Grid item xs={12} sm={3}>
            <DateInput
              name={"initialDate"}
              label={t(translationPath + "initial_date")}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <DateInput
              name={"endDate"}
              label={t(translationPath + "end_date")}
            />
          </Grid>

          <Message />
        </Grid>
      )}
    />
  );
};

export default TicketAutoFinishRule;
