import { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TuneIcon from "@mui/icons-material/Tune";
import LanguageIcon from "@mui/icons-material/Language";
import DataObjectIcon from "@mui/icons-material/DataObject";
import PublicIcon from "@mui/icons-material/Public";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ExtensionIcon from "@mui/icons-material/Extension";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import WebIcon from "@mui/icons-material/Web";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DomainIcon from "@mui/icons-material/Domain";
import EventIcon from "@mui/icons-material/Event";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import RuleIcon from "@mui/icons-material/Rule";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ForumIcon from "@mui/icons-material/Forum";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImageIcon from "@mui/icons-material/Image";
import PaymentIcon from "@mui/icons-material/Payment";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CachedIcon from "@mui/icons-material/Cached";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaidIcon from "@mui/icons-material/Paid";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import SyncIcon from "@mui/icons-material/Sync";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import DvrIcon from "@mui/icons-material/Dvr";
import BuildIcon from "@mui/icons-material/Build";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SpaIcon from "@mui/icons-material/Spa";
import Groups2Icon from "@mui/icons-material/Groups2";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import BlockIcon from "@mui/icons-material/Block";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import NotesIcon from "@mui/icons-material/Notes";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ChecklistIcon from "@mui/icons-material/Checklist";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import GradingIcon from "@mui/icons-material/Grading";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import QuizIcon from "@mui/icons-material/Quiz";
import ArticleIcon from "@mui/icons-material/Article";
import TodayIcon from "@mui/icons-material/Today";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SensorsIcon from "@mui/icons-material/Sensors";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DownloadIcon from "@mui/icons-material/Download";
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import {
  PersonalInjuryIcon,
  StethoscopeIcon,
  PersonManageIcon,
  PersonCheckIcon,
  PersonCancelIcon,
  WeatherNight,
  WeatherSunsetUp,
  WeatherSunsetDown,
} from "./CustomIcons";
interface IDynamicIcon {
  icon?: string | null;
}

const DynamicIcon: FC<SvgIconProps & IDynamicIcon> = ({ icon }, props) => {
  switch (icon) {
    case "HomeIcon":
      return <HomeIcon {...props} />;
    case "PersonIcon":
      return <PersonIcon {...props} />;
    case "BusinessIcon":
      return <BusinessIcon {...props} />;
    case "HistoryEduIcon":
      return <HistoryEduIcon {...props} />;
    case "ContactPageIcon":
      return <ContactPageIcon {...props} />;
    case "AccountBoxIcon":
      return <AccountBoxIcon {...props} />;
    case "ApartmentIcon":
      return <ApartmentIcon {...props} />;
    case "TuneIcon":
      return <TuneIcon {...props} />;
    case "LanguageIcon":
      return <LanguageIcon {...props} />;
    case "DataObjectIcon":
      return <DataObjectIcon {...props} />;
    case "PublicIcon":
      return <PublicIcon {...props} />;
    case "WebIcon":
      return <WebIcon {...props} />;
    case "SsidChartIcon":
      return <SsidChartIcon {...props} />;
    case "AccountBalanceIcon":
      return <AccountBalanceIcon {...props} />;
    case "ExtensionIcon":
      return <ExtensionIcon {...props} />;
    case "SettingsSuggestIcon":
      return <SettingsSuggestIcon {...props} />;
    case "LocationCityIcon":
      return <LocationCityIcon {...props} />;
    case "CorporateFareIcon":
      return <CorporateFareIcon {...props} />;
    case "FlagCircleIcon":
      return <FlagCircleIcon {...props} />;
    case "MapsHomeWorkIcon":
      return <MapsHomeWorkIcon {...props} />;
    case "HolidayVillageIcon":
      return <HolidayVillageIcon {...props} />;
    case "PhonelinkIcon":
      return <PhonelinkIcon {...props} />;
    case "DisplaySettingsIcon":
      return <DisplaySettingsIcon {...props} />;
    case "DomainIcon":
      return <DomainIcon {...props} />;
    case "EventIcon":
      return <EventIcon {...props} />;
    case "SupportAgentIcon":
      return <SupportAgentIcon {...props} />;
    case "RuleIcon":
      return <RuleIcon {...props} />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon {...props} />;
    case "ConfirmationNumberIcon":
      return <ConfirmationNumberIcon {...props} />;
    case "ForumIcon":
      return <ForumIcon {...props} />;
    case "PermPhoneMsgIcon":
      return <PermPhoneMsgIcon {...props} />;
    case "PendingActionsIcon":
      return <PendingActionsIcon {...props} />;
    case "NewspaperIcon":
      return <NewspaperIcon {...props} />;
    case "NotificationsIcon":
      return <NotificationsIcon {...props} />;
    case "ImageIcon":
      return <ImageIcon {...props} />;
    case "PaymentIcon":
      return <PaymentIcon {...props} />;
    case "SmartToyIcon":
      return <SmartToyIcon {...props} />;
    case "MarkAsUnreadIcon":
      return <MarkAsUnreadIcon {...props} />;
    case "CachedIcon":
      return <CachedIcon {...props} />;
    case "LocalShippingIcon":
      return <LocalShippingIcon {...props} />;
    case "PaidIcon":
      return <PaidIcon {...props} />;
    case "ShareLocationIcon":
      return <ShareLocationIcon {...props} />;
    case "ImportantDevicesIcon":
      return <ImportantDevicesIcon {...props} />;
    case "VolunteerActivismIcon":
      return <VolunteerActivismIcon {...props} />;
    case "TextSnippetIcon":
      return <TextSnippetIcon {...props} />;
    case "Diversity3Icon":
      return <Diversity3Icon {...props} />;
    case "GroupsIcon":
      return <GroupsIcon {...props} />;
    case "BuildCircleIcon":
      return <BuildCircleIcon {...props} />;
    case "DvrIcon":
      return <DvrIcon {...props} />;
    case "BuildIcon":
      return <BuildIcon {...props} />;
    case "KeyIcon":
      return <KeyIcon {...props} />;
    case "SyncIcon":
      return <SyncIcon {...props} />;
    case "HomeWorkIcon":
      return <HomeWorkIcon {...props} />;
    case "PersonalInjury":
      return <PersonalInjuryIcon />;
    case "Stethoscope":
      return <StethoscopeIcon />;
    case "PersonManage":
      return <PersonManageIcon />;
    case "PersonCheck":
      return <PersonCheckIcon />;
    case "PersonCancel":
      return <PersonCancelIcon />;
    case "LeakAddIcon":
      return <LeakAddIcon {...props} />;
    case "FavoriteIcon":
      return <FavoriteIcon {...props} />;
    case "SpaIcon":
      return <SpaIcon {...props} />;
    case "Groups2Icon":
      return <Groups2Icon {...props} />;
    case "Diversity2Icon":
      return <Diversity2Icon {...props} />;
    case "ContentPasteIcon":
      return <ContentPasteIcon {...props} />;
    case "BlockIcon":
      return <BlockIcon {...props} />;
    case "NotesIcon":
      return <NotesIcon {...props} />;
    case "FormatAlignLeftIcon":
      return <FormatAlignLeftIcon {...props} />;
    case "FactCheckIcon":
      return <FactCheckIcon {...props} />;
    case "ChecklistIcon":
      return <ChecklistIcon {...props} />;
    case "MonitorHeartIcon":
      return <MonitorHeartIcon {...props} />;
    case "GradingIcon":
      return <GradingIcon {...props} />;
    case "CalendarMonthIcon":
      return <CalendarMonthIcon {...props} />;
    case "DateRangeIcon":
      return <DateRangeIcon {...props} />;
    case "EventAvailableIcon":
      return <EventAvailableIcon {...props} />;
    case "AssignmentTurnedInIcon":
      return <AssignmentTurnedInIcon {...props} />;
    case "QuizIcon":
      return <QuizIcon {...props} />;
    case "ArticleIcon":
      return <ArticleIcon {...props} />;
    case "RecentActorsIcon": 
      return <RecentActorsIcon {...props}/>;
    case "TodayIcon":
      return <TodayIcon {...props} />;
    case "EventNoteIcon":
      return <EventNoteIcon {...props} />;
    case "ViewHeadlineIcon":
      return <ViewHeadlineIcon {...props} />;
    case "PrecisionManufacturingIcon":
      return <PrecisionManufacturingIcon {...props} />;
    case "SensorsIcon":
      return <SensorsIcon {...props} />;
    case "WhatsAppIcon":
      return <WhatsAppIcon {...props} />;
    case "CalendarTodayIcon":
      return <CalendarTodayIcon {...props} />;
    case "WeatherSunsetDown":
      return (
        <SvgIcon component={WeatherSunsetDown} inheritViewBox {...props} />
      );
    case "WeatherSunsetUp":
      return <SvgIcon component={WeatherSunsetUp} inheritViewBox {...props} />;
    case "WeatherNight":
      return <SvgIcon component={WeatherNight} inheritViewBox {...props} />;
    case "DownloadIcon": {
      return <DownloadIcon {...props} />;
    }

    default:
      return null;
  }
};

export default DynamicIcon;
