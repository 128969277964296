import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";

import FormStepperHeader from "./FormStepperHeader";
import FormStepperActions from "./FormStepperActions";
import { IFormStepperProps } from "./models";
import NotAvaiableModal from "./NotAvaiableModal";
import { genericMemo } from "../../../../../../components/SubFormArray/genericMemo";

const FormStepper = <T extends FieldValues>({
  steps,
  finishLabel,
  onFinish,
  initialStep,
  canGoBack,
  error,
}: IFormStepperProps<T>) => {
  const { trigger } = useFormContext<T>();

  const [open, setOpen] = useState(false);

  const [activeStep, setActiveStep] = React.useState(initialStep || 0);

  const handleNext = useCallback(async () => {
    const valid = await trigger(steps[activeStep].fields);
    if (valid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [trigger, activeStep, steps]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (error && error.errors.GetCalendarConsultationAvailable) {
      setActiveStep(3);
      setOpen(true);
    }
  }, [error]);

  return (
    <Stack
      sx={{
        height: "100%",
        paddingInline: 1,
        gap: 2,
        width: "100%",
        overflow: "auto",
      }}
    >
      <NotAvaiableModal onClose={() => setOpen(false)} open={open} />
      <FormStepperHeader<T> activeStep={activeStep} steps={steps} />
      <Box
        width={"100%"}
        overflow={"auto"}
        pt={2}
        minHeight={350}
        sx={{ paddingInline: 2, height: "100%" }}
      >
        {steps[activeStep].element}
      </Box>
      <FormStepperActions
        finishLabel={finishLabel}
        activeStep={activeStep}
        canGoBack={canGoBack}
        isLastStep={activeStep === steps.length - 1}
        onBack={handleBack}
        onNext={handleNext}
        onFinish={onFinish}
      />
    </Stack>
  );
};

export default genericMemo(FormStepper);
