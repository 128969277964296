import { Box, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowId, useGridApiRef } from "@mui/x-data-grid";
import {
  IMultipleRowActions,
  NoRowsMessage,
  SelectedsActionBar,
  TableActions,
  useRole,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { IDocsVersion } from "../../services/docsVersion.service";
import { TDocType } from "../../models/documents";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import IosShareIcon from "@mui/icons-material/IosShare";
import TableList, { ITableListRef } from "./TableList";

interface ITableContainer {
  columns?: GridColDef<any>[];
  rows: any[];
  lastVersion: IDocsVersion;
  docTypeSelected: TDocType | null;
  children: any;
  multipleRowsActions?: IMultipleRowActions[];
  showSelects?: boolean;
}

const translationPath = "page.register.documents.";

const TableContainer = ({
  columns,
  rows,
  lastVersion,
  docTypeSelected,
  children,
  showSelects = false,
  multipleRowsActions,
}: ITableContainer) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selecteds, setSelecteds] = useState<GridRowId[]>([]);
  const createPermission = useRole(["Create"]);
  const readPermission = useRole(["Read"]);
  const apiRef = useGridApiRef();
  const ref = useRef<ITableListRef>(null);
  const routeParams = useParams();
  const realColumns = [
    ...columns!.map((col) => ({
      ...col,
      flex: !col.width ? 1 / (columns!.length - 1) : 0,
      minWidth: 100,
    })),
  ];

  const handleAdd = () => {
    navigate(
      `/e/${routeParams.eI}/dashboard/register/documents/${docTypeSelected?.id}/new`
    );
  };

  const handleCsv = () => {
    ref.current?.handleCsv();
  };

  const hasCreatePermission =
    !lastVersion?.version && docTypeSelected && createPermission;

  const hasReadPermission =
    docTypeSelected && rows.length > 0 && readPermission;

  return (
    <Stack sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
      <Stack direction={"row"} justifyContent={"flex-end"} mb={3}>
        <Box height={"36px"}>
          <AnimatePresence>
            {selecteds.length === 0 && (
              <TableActions
                showAddButton={hasCreatePermission ? true : false}
                onAddHandler={handleAdd}
                addButtonLabel={
                  t(translationPath + "add_document") || "Add Document"
                }
                actionMenuItems={[
                  {
                    text: t(translationPath + "export_csv") || "Export CSV",
                    icon: <IosShareIcon fontSize="small" />,
                    action: handleCsv,
                  },
                  ...[],
                ]}
              />
            )}
          </AnimatePresence>
        </Box>
      </Stack>
      <Typography variant="h5" mb={2}>
        {t(translationPath + "documents" || "Documents")}
      </Typography>
      {children}
      {!readPermission && docTypeSelected && <NoRowsMessage inCenter={false} />}
      {hasReadPermission && (
        <TableList
          rows={rows}
          columns={realColumns}
          onRowSelectionModelChange={(rowSelectionModel, details) => {
            setSelecteds(rowSelectionModel);
          }}
          showSelects={showSelects}
          fileName={docTypeSelected.name || ""}
          ref={ref}
        />
      )}
      <AnimatePresence>
        {selecteds.length > 0 && (
          <SelectedsActionBar
            api={apiRef}
            selecteds={selecteds}
            actions={multipleRowsActions}
          />
        )}
      </AnimatePresence>
    </Stack>
  );
};

export default TableContainer;
