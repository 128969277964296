import {
  AutoCompleteMulti,
  DateTime,
  FormInput,
  ISelectType,
  SwitchInput,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Box, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { SelectEstablishment } from "./SelectEstablishment";
import { SelectEvaluationForm } from "./SelectEvaluationForm";
import MainApi from "../../../../../../../services/mainApi.service";
import { useTranslation } from "react-i18next";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { TMessageForm } from "./messageSchema";

const TASY_SCHEDULE_RECORDS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleRecord/";
const tasyScheduleRecordsService = new MainApi<ISelectType>(
  TASY_SCHEDULE_RECORDS_ROUTE
);

const TASY_SCHEDULE_CLASSIFICATIONS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleClassification/";
const tasyScheduleClassificationsService = new MainApi<ISelectType>(
  TASY_SCHEDULE_CLASSIFICATIONS_ROUTE
);

const TASY_SCHEDULE_TYPES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleType/";
const tasyScheduleTypesService = new MainApi<ISelectType>(
  TASY_SCHEDULE_TYPES_ROUTE
);

const TASY_HEALTH_INSURANCES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyHealthInsurance/";
const tasyHealthInsurancesService = new MainApi<ISelectType>(
  TASY_HEALTH_INSURANCES_ROUTE
);

const translationPath = "components.messages.";

export const MessageFields = memo(() => {
  const { t } = useTranslation();

  const { formState } = useFormContext<TMessageForm>();

  const exceptionError = !!formState.errors.exception;

  const { automation } = useAutomationRules();

  const automationCode = automation?.code;

  return (
    <Box sx={{ pl: 2 }}>
      <Grid
        container
        spacing={2}
        sx={(t) => ({
          pr: 3,
          pl: 1,
          py: 3,
          border: `1px solid ${
            exceptionError ? t.palette.error.main : t.palette.divider
          }`,
          borderRadius: 2,
        })}
      >
        <Grid item sm={12}>
          <Typography fontWeight="bold">Se</Typography>
        </Grid>

        <Grid item sm={12} textAlign={"end"}>
          <SwitchInput name="enable" label={t(translationPath + "enable")} />
          <SwitchInput
            name="exception"
            label={t(translationPath + "exception")}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="name"
            fullWidth
            label={t(translationPath + "name") || "Name"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateTime
            name="initialDate"
            label={t(translationPath + "initial_date")}
            isClearable
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateTime
            name="endDate"
            label={t(translationPath + "end_date")}
            isClearable
          />
        </Grid>

        {exceptionError && (
          <Grid item xs={12} sm={12}>
            <Typography color="error" variant="body2">
              Ao habilitar a exceção, ao menos um dos campos abaixo são
              obrigatórios.
            </Typography>
          </Grid>
        )}

        {automation &&
          (automationCode === "1" ||
            automationCode === "2" ||
            automationCode === "3" ||
            automationCode === "4" ||
            automationCode === "5" ||
            automationCode === "6") && <SelectEstablishment />}

        {automationCode &&
          (automationCode === "1" ||
            automationCode === "2" ||
            automationCode === "4" ||
            automationCode === "5" ||
            automationCode === "6") && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                name="tasyScheduleRecords"
                label={t(translationPath + "schedule_records")}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={tasyScheduleRecordsService.list}
              />
            </Grid>
          )}

        {automationCode &&
          (automationCode === "1" ||
            automationCode === "2" ||
            automationCode === "5" ||
            automationCode === "6") && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                name="tasyScheduleClassifications"
                label={t(translationPath + "schedule_classifications")}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={tasyScheduleClassificationsService.list}
              />
            </Grid>
          )}

        {automationCode && automationCode === "3" && (
          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="tasyScheduleTypes"
              label={t(translationPath + "schedule_types")}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={tasyScheduleTypesService.list}
            />
          </Grid>
        )}

        {automation &&
          (automationCode === "1" ||
            automationCode === "2" ||
            automationCode === "3" ||
            automationCode === "4" ||
            automationCode === "5" ||
            automationCode === "6") && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                name="tasyHealthInsurances"
                label={t(translationPath + "schedule_health_insurances")}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={tasyHealthInsurancesService.list}
              />
            </Grid>
          )}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={(t) => ({
          mt: 5,
          pr: 3,
          pl: 1,
          py: 3,
          border: `1px solid ${t.palette.divider}`,
          borderRadius: 2,
        })}
      >
        <Grid item sm={12}>
          <Typography fontWeight="bold">Então</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <SwitchInput name="send" label={t(translationPath + "send")} />
        </Grid>
        <SelectEvaluationForm />
      </Grid>
    </Box>
  );
});
