import { useCallback, useEffect, useState } from "react";
import { useFetch, useFormContext } from "@4uhub/lib4uhub";
import { format, parse } from "date-fns";

import { TAppointment, TSearchConsult, TSearchExam } from "../../schema";
import { ScheduleAppointmentService } from "../../../../../Schedule.service";
import { ETasyScheduleTypeCode, IAvaiableSchedule } from "../../../../../model";

export const useSchedule = (defaultDate: string) => {
  const [month, setMonth] = useState<string>(
    format(new Date(defaultDate), "MM/yyyy")
  );

  const { getValues, resetField } = useFormContext<TAppointment>();

  const [datesLoading, setDatesLoading] = useState(false);

  const [schedulesLoading, setSchedulesLoading] = useState(false);

  const [dates, setDates] = useState<Date[]>([]);

  const [schedules, setSchedules] = useState<IAvaiableSchedule[]>([]);

  const [date, setDate] = useState<Date | null>(null);

  const { sendRequest: getConsultationDates } = useFetch(
    ScheduleAppointmentService.getConsultationAvaiableDate
  );

  const { sendRequest: getConsultationSchedules } = useFetch(
    ScheduleAppointmentService.getConsultationAvaiableSchedules
  );

  const { sendRequest: getExamDates } = useFetch(
    ScheduleAppointmentService.getExamAvaiableDate
  );

  const { sendRequest: getExamSchedules } = useFetch(
    ScheduleAppointmentService.getExamAvaiableSchedules
  );

  const fetchConsulationDates = useCallback(
    async (search: TSearchConsult) => {
      const { data, success } = await getConsultationDates({
        MedicalSpecialtyCode: search.speciality?.id,
        MonthYear: month,
        CalendarIdentifier: search.professional?.id
          ? String(search.professional?.id)
          : undefined,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
        MinDate: new Date(search.date).toDateString(),
      });
      if (success && data) {
        const dates = data.dates.map((date) => {
          return parse(date, "yyyy-MM-dd", new Date());
        });

        setDates(dates);
      }
    },
    [getConsultationDates, month]
  );

  const fetchExamDates = useCallback(
    async (search: TSearchExam) => {
      const { data, success } = await getExamDates({
        GroupingIdentifier: Number(search.examGroup.id),
        MonthYear: month,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
        MinDate: new Date(search.date).toDateString(),
      });
      if (success && data) {
        setDates(data.dates.map((d) => new Date(d)));
      }
    },
    [getExamDates, month]
  );

  const fetchDates = useCallback(async () => {
    const { search } = getValues();
    setDatesLoading(true);
    if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
      await fetchConsulationDates(search);
    } else {
      await fetchExamDates(search);
    }
    setDatesLoading(false);
  }, [fetchConsulationDates, getValues, fetchExamDates]);

  const fetchConsultationSchedules = useCallback(
    async (search: TSearchConsult, pDate: Date) => {
      const { data, success } = await getConsultationSchedules({
        Date: format(pDate, "MM/dd/yyyy"),
        MedicalSpecialtyCode: search.speciality?.id,
        MonthYear: month,
        CalendarIdentifier: search.professional?.id
          ? String(search.professional?.id)
          : undefined,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
      });
      if (success && data) {
        setSchedules(data);
      }
    },
    [month, getConsultationSchedules]
  );

  const fetchExamSchedules = useCallback(
    async (search: TSearchExam, pDate: Date) => {
      const { data, success } = await getExamSchedules({
        Date: format(pDate, "MM/dd/yyyy"),
        GroupingIdentifier: Number(search.examGroup.id),
        MonthYear: month,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
      });
      if (success && data) {
        setSchedules(data);
      }
    },
    [month, getExamSchedules]
  );

  const fetchSchedules = useCallback(
    async (pDate: Date) => {
      const { search } = getValues();
      setSchedulesLoading(true);
      if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
        await fetchConsultationSchedules(search, pDate);
      } else {
        await fetchExamSchedules(search, pDate);
      }
      setSchedulesLoading(false);
    },
    [getValues, fetchConsultationSchedules, fetchExamSchedules]
  );

  const onVisibleMonthsChange = useCallback((month: Date) => {
    setMonth(format(month, "MM/yyyy"));
  }, []);

  const onDateChange = useCallback(
    (d: Date | null) => {
      if (d) {
        fetchSchedules(d);
      }
      setDate(d);
      resetField("schedule");
    },
    [fetchSchedules, resetField]
  );

  useEffect(() => {
    fetchDates();
  }, [fetchDates]);

  return {
    onDateChange,
    onVisibleMonthsChange,
    date,
    dates,
    schedules,
    datesLoading,
    schedulesLoading,
  };
};
