import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IContents } from "../../../../models/content";
import { Chip } from "@mui/material";
import { ptBR, enUS } from "date-fns/locale";
import { format } from "date-fns";

const CONTENT_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Content/";
const contentService = new MainApiService<IContents>(CONTENT_ROUTE);

const translationPath = "page.register.news.";



const NewsPage = () => {
  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IContents>[] = [
    {
      field: "title",
      headerName: t("components.content.title") || "Title",
      renderCell: (params) => {
        const title = params.row.contents.find(
          (content) => content.language.code === language
        )?.title;
        return title ? title : "-";
      },
    },
    {
      field: "publicationDate",
      headerName: t("components.content.publication_date") || "Publication date",
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", " -");
      },
    },
    {
      field: "contentStatus",
      headerName: t("components.content.content_status") || "Content status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row.contentStatus.code === "2"
                ? t("components.content.published")
                : t("components.content.not_published")
            }
            color={params.row.contentStatus.code === "2" ? "success" : "error"}
            size="small"
          />
        );
      },
    },
    {
      field: "enable",
      headerName: t("components.content.status") || "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row.enable
                ? t("components.content.enabled")
                : t("components.content.inactive")
            }
            color={params.row.enable ? "success" : "error"}
            size="small"
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IContents>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IContents>
      showDefaultMacroFilters={false}
      service={contentService}
      columns={columns}
      title={t(translationPath + "all_news")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      serviceProps={{ ContentTypeCode: "1" }}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a news"
      }
      addButtonLabel={t(translationPath + "add_news") || "Add News"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default NewsPage;
