import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AutoComplete, useFetch, useFormContext } from "@4uhub/lib4uhub";

import { TAppointment } from "../../schema";
import { ScheduleAppointmentService } from "../../../../../Schedule.service";
import { IProfessional } from "../../../../../model";

const Professional = () => {
  const [professionals, setProfessionals] = useState<IProfessional[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getProfessionals);

  const { control, setValue } = useFormContext<TAppointment>();

  const speciality = useWatch({ name: "search.speciality", control });

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    if (!speciality) return;

    const { success, data } = await sendRequest({
      MedicalSpecialtyCode: String(speciality.id),
    });

    if (success && data) {
      setValue("search.professional", undefined);
      setProfessionals(data);
    }
  }, [sendRequest, speciality, setValue]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      options={professionals.map((s) => ({
        id: String(s.identifier),
        name: s.individualName,
      }))}
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("components.scheduleAssistant.newSchedule.search.professional")}
      name="search.professional"
      sx={{ mb: 2 }}
    />
  );
};

export default memo(Professional);
