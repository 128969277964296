import { Box, Stack, Typography } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import pt from "date-fns/locale/pt-BR";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Day } from "./Day";

interface IScheduleCalendarProps {
  datesLoading: boolean;
  date?: Date | null;
  onDateChange: (d: Date | null) => void;
  onMonthChange: (month: Date) => void;
  dates: Date[];
  defaultValue?: Date;
}

const ScheduleCalendar: React.FC<IScheduleCalendarProps> = ({
  datesLoading,
  date,
  onDateChange,
  onMonthChange,
  dates,
  defaultValue,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const adapterLocale = useMemo(() => {
    switch (language) {
      case "en-US": {
        return en;
      }
      case "es": {
        return es;
      }
      default: {
        return pt;
      }
    }
  }, [language]);

  return (
    <Stack>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <DateCalendar
          disablePast
          referenceDate={defaultValue}
          loading={datesLoading}
          value={date}
          onChange={onDateChange}
          dayOfWeekFormatter={(_, date: Date) =>
            format(date, "dddd").charAt(0).toUpperCase()
          }
          onMonthChange={onMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: Day,
          }}
          slotProps={{
            day: {
              highlightedDays: dates,
            } as any,
          }}
        />
      </LocalizationProvider>
      <Stack alignItems={"center"} flexDirection={"row"} gap={2}>
        <Box
          sx={(t) => ({
            border: `1px solid ${t.palette.primary.main}`,
            borderRadius: 10,
            height: 10,
            width: 10,
          })}
        />
        <Typography>
          {t("components.scheduleAssistant.newSchedule.schedule.freeDays")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(ScheduleCalendar);
