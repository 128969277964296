import { z } from "zod";

const translationPath = "chatBot.page.service.ticketServiceArea.errors.";

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_services" }
);

const ticketServiceArea = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_service_area" }
);

const ticketChannelSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_channel" }
);

const ticketDefaultDocSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_default_doc" }
);

export const ticketServiceAreaSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  enableAllTheTime: z.boolean(),
  enableSpecialTime: z.boolean(),
  isIdentification: z.boolean(),
  name: z
    .string()
    .min(1, translationPath + "name")
    .refine((val) => val.length <= 24, translationPath + "name_characteres"),
  code: z.string().min(1, translationPath + "code"),
  summary: z
    .string()
    .min(1, translationPath + "summary")
    .refine((val) => val.length <= 72, translationPath + "summary_characteres"),
  displayOrder: z.string().min(1, translationPath + "display_order"),
  ticketService: ticketServiceSchema,
  ticketServiceArea: ticketServiceArea.optional().nullable(),
  ticketChannel: ticketChannelSchema.optional().nullable(),
  ticketDefaultDoc: ticketDefaultDocSchema.optional().nullable(),
});

export type TTicketServiceAreaForm = z.infer<typeof ticketServiceAreaSchema>;
