import { z } from "zod";
import { ETasyScheduleTypeCode } from "../../../model";

const specialitySchema = z.object(
  {
    id: z.string(),
  },
  {
    required_error: "components.scheduleAssistant.newSchedule.steps.search.specialityError",
    invalid_type_error:
      "components.scheduleAssistant.newSchedule.steps.search.specialityError",
  }
);

const professionalSchema = z.object({
  id: z.string(),
});

const periodSchema = z.object({
  id: z.string(),
  code: z.string(),
});

const examSchema = z.object(
  {
    id: z.string(),
  },
  {
    required_error: "components.scheduleAssistant.newSchedule.steps.search.examError",
    invalid_type_error: "components.scheduleAssistant.newSchedule.steps.search.examError",
  }
);

const searchConsult = z.object({
  type: z.literal(ETasyScheduleTypeCode.CONSULTATION),
  speciality: specialitySchema,
  professional: professionalSchema.optional().nullable(),
  date: z.date(),
  period: z.array(periodSchema).optional(),
});

const searchExam = z.object({
  type: z.literal(ETasyScheduleTypeCode.EXAM),
  examGroup: examSchema,
  date: z.date(),
  period: z.array(periodSchema).optional(),
});

export type TSearchExam = z.infer<typeof searchExam>;
export type TSearchConsult = z.infer<typeof searchConsult>;

const searchSchema = z.discriminatedUnion("type", [searchConsult, searchExam]);

const scheduleSchema = z.object(
  {
    descriptionSite: z.string(),
    identifier: z.number(),
    calendarStartDate: z.string(),
    calendarEndDate: z.string(),
    minutesDuration: z.number(),
  },
  { required_error: "components.scheduleAssistant.newSchedule.steps.schedule.error" }
);

const patientSchema = z.object(
  {
    id: z.string(),
    individualName: z.string(),
    identifier: z.string(),
    document: z.string(),
  },
  {
    invalid_type_error: "components.schedule.newSchedule.required",
  }
);

const insuranceSchema = z.object(
  {
    name: z.string().min(1, { message: "required" }),
    id: z.string().min(1),
  },
  { required_error: "components.scheduleAssistant.newSchedule.steps.insurance.error" }
);

export const appointmentSchema = z.object({
  patient: patientSchema,
  insurance: insuranceSchema,
  search: searchSchema,
  schedule: scheduleSchema,
});

export type TAppointment = z.infer<typeof appointmentSchema>;
