import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { TTimeRuleForm, timeRuleSchema } from "./timeRuleSchema";
import {
  createTimeRule,
  getTimeRuleById,
  updateTimeRuleById,
} from "../../../../../../../services/timeRule.service";
import { useCallback } from "react";
import { ITimeRuleProps } from "./models";
import MainApi from "../../../../../../../services/mainApi.service";
import { TimeClassification } from "./TimeClassification";

const translationPath = "components.timeRules.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERICS_ROUTE);

const TimeRule = ({
  id,
  ticketAutomationRuleId,
  onBackButton,
}: ITimeRuleProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(createTimeRule);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateTimeRuleById);

  const { sendRequest: get, loading: gLoading } = useFetch(getTimeRuleById);

  const updateHandler = (v: TTimeRuleForm, id: string) =>
    update({
      item: {
        id: id,
        value: Number(v.value),
        ticketAutomationRuleId: ticketAutomationRuleId ?? "",
        ticketAutomationTimeClassificationId: v.timeClassification
          ? v.timeClassification.id
          : "",
        typeofTypeFractionId: v.type.id,
      },
      id: id,
    });

  const saveHandler = (v: TTimeRuleForm) =>
    save({
      value: Number(v.value),
      ticketAutomationRuleId: ticketAutomationRuleId ?? "",
      ticketAutomationTimeClassificationId: v.timeClassification
        ? v.timeClassification.id
        : "",
      typeofTypeFractionId: v.type.id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TTimeRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TTimeRuleForm = {
          ...data,
          value: data.value.toString(),
          timeClassification: {
            id: data.ticketAutomationTimeClassification.id,
            name: data.ticketAutomationTimeClassification.timeClassification
              .name,
          },
          type: data.typeofTypeFraction,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTimeRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "time_rule")}
      schema={timeRuleSchema}
      subtitle={t(translationPath + "new_time_rule")}
      subtitleWatchField={"value"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <TimeClassification />

          <Grid item xs={12} sm={5}>
            <AutoComplete
              params={{ Identifier: "TypeofTimeFraction" }}
              name="type"
              label={t(translationPath + "type") || "Type"}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={genericService.list}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              mask={/^[0-9]*$/}
              label={t(translationPath + "value") || "Value"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default TimeRule;
