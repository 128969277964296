import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../../services/mainApi.service";
import { useCallback, useState } from "react";
import {
  getCaptationById,
  updateCaptationById,
} from "../../../../../../services/healthProgramCaptation.service";
import { TCaptationForm, captationSchema } from "./CaptationSchema";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";
import { ICaptation } from "../../../../../../models/health-programs-fundraising";
import ApproveCaptation from "./ApproveCaptation";
import DisapproveCaptation from "./DisapproveCaptation";

const translationPath = "page.4uHcp.hcp.fundraising.";

const HEALTH_PROGRAM_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgram/";
const healthProgramService = new MainApi<ISelectType>(HEALTH_PROGRAM_ROUTE);

const Captation = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<ICaptation>();

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateCaptationById);

  const { sendRequest: get, loading: gLoading } = useFetch(getCaptationById);

  const updateHandler = (v: TCaptationForm, id: string) =>
    update({
      item: {
        id: v.id || "",
        healthProgramId: v.healthProgram.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TCaptationForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setData(data);

        const newData: TCaptationForm = {
          id: data.id,
          healthProgram: data.healthProgram,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = uLoading || gLoading;

  return (
    <FormContainer<TCaptationForm>
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "captation")}
      schema={captationSchema}
      subtitle={t(translationPath + "new_captation")}
      subtitleWatchField={"healthProgram.name"}
      fields={() => (
        <Grid container spacing={2}>
          {data && data.healthProgramCaptationStatus.code === "3" && (
            <Grid item sm={12} display={"flex"} justifyContent={"end"} gap={2}>
              <DisapproveCaptation
                healthProgramCaptationId={data.id}
                type="button"
                redirect
              />
              <ApproveCaptation
                healthProgramCaptationId={data.id}
                type="button"
                redirect
              />
            </Grid>
          )}

          {data && (
            <>
              <Grid item xs={12} sm={6}>
                <FormInformation
                  data={data.name}
                  label={t(translationPath + "name")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInformation
                  data={data.document}
                  label={t(translationPath + "document")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInformation
                  data={data.healthProgramCaptationType.name}
                  label={t(translationPath + "captation_type")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInformation
                  data={data.healthProgramCaptationStatus.name}
                  label={t(translationPath + "captation_status")}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "health_program") || "Health Program"}
              name="healthProgram"
              request={healthProgramService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Captation;
