import { z } from "zod";

const translationPath = "page.4uHcp.hcp.workGroups.errors.";

const language = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "language" }
);

const healthProgramWorkGroupTypeLanguageSchema = z.object({
  name: z.string().min(1, translationPath + "name"),
  description: z.string().optional().nullable(),
  language: language,
});

const healthProgramWorkGroupMemberSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const healthProgramWorkGroupTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional(),
  },
  { invalid_type_error: translationPath + "work_group_type" }
);

export const workGroupSchema = z.object({
  id: z.string().optional(),
  healthProgramWorkGroupType: healthProgramWorkGroupTypeSchema,
  healthProgramWorkGroupMembers: z
    .array(healthProgramWorkGroupMemberSchema)
    .optional(),
  healthProgramWorkGroupLanguages: z
    .array(healthProgramWorkGroupTypeLanguageSchema)
    .min(1, translationPath + "languages"),
});

export type TWorkGroupForm = z.infer<typeof workGroupSchema>;
