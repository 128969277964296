import { GridColDef } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApi from "../../../../../../services/mainApi.service";
import { useMemo } from "react";
import { IHealthProgramsParticipants } from "../../../../../../models/health-programs-participantes";
import { documentFormat } from "../../../../../../utils/conversions";

const HEALTH_PROGRAMS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/HealthProgram/GetHealthProgramParticipantsWithPagination/";
const healthProgramsService = new MainApi<IHealthProgramsParticipants>(
  HEALTH_PROGRAMS_ROUTE
);

const translationPath = "page.4uHcp.hcp.participants.";

const ParticipantsPage = () => {
  const { healthProgramId } = useParams();

  const { t } = useTranslation();

  const columns: GridColDef<IHealthProgramsParticipants>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "document",
      headerName: t(translationPath + "document") || "Document",
      renderCell: (params) =>
        params.value ? documentFormat(params.value) : "-",
    },
  ];

  const props = useMemo(() => {
    return {
      HealthProgramId: healthProgramId,
    };
  }, [healthProgramId]);

  return (
    <Table<IHealthProgramsParticipants>
      showDefaultMacroFilters={false}
      service={healthProgramsService}
      columns={columns}
      title={t(translationPath + "participants")}
      searchInput
      showButton={false}
      defaultActions={false}
      showDelete={false}
      showDeleteButton={false}
      defaultPageSize={20}
      backButton
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a participant"
      }
      addButtonLabel={
        t(translationPath + "add_participant") || "Add Participant"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ParticipantsPage;
