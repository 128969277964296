import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormInput,
  useFetch,
  FilePicker,
  Button,
} from "@4uhub/lib4uhub";
import {
  THealthProgramImportForm,
  healthProgramImportSchema,
} from "./HealthProgramImportSchema";
import { createImport } from "../../../../../../services/healthProgramImports.service";
import { useParams } from "react-router-dom";
import useDownload from "../../../../../../hooks/useDownload";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useCallback } from "react";

const translationPath = "page.4uHcp.hcp.healthProgramImports.";

const DOWNLOAD_TEMPLATE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramImports/GetCsvTemplate";

const HealthProgramImport = () => {
  const { t } = useTranslation();

  const { downloadFileParams, loading: dLoading } = useDownload();

  const { healthProgramId } = useParams();

  const { sendRequest: save, loading: uLoading } = useFetch(createImport);

  const importFile = useCallback(
    (v: THealthProgramImportForm) => {
      return save({
        File: v.file,
        ImportPersonName: v.importPersonName,
        OriginOfDemand: v.originOfDemand,
        HealthProgramId: healthProgramId || "",
      });
    },
    [healthProgramId, save]
  );

  const loading = uLoading;

  const downloadTemplateHandler = useCallback(() => {
    downloadFileParams(DOWNLOAD_TEMPLATE_ROUTE, {}, "template.csv");
  }, [downloadFileParams]);

  return (
    <FormContainer<THealthProgramImportForm>
      saveFunction={importFile}
      loading={loading}
      title={t(translationPath + "import")}
      schema={healthProgramImportSchema}
      subtitle={t(translationPath + "new_import")}
      subtitleWatchField={"importPersonName"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <Button
              variant="contained"
              loading={dLoading}
              startIcon={!dLoading ? <FileDownloadIcon /> : undefined}
              onClick={downloadTemplateHandler}
            >
              {t(translationPath + "download_template")}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              name="importPersonName"
              label={t(translationPath + "import_person_name")}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              name="originOfDemand"
              label={t(translationPath + "origin_of_demand")}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item sm={12}>
            <FilePicker
              name="file"
              label="Selecionar arquivo"
              accept="csv"
              maxSize={1}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HealthProgramImport;
