import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormEmojiTextInput,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../../services/mainApi.service";
import { useCallback, useState } from "react";
import {
  TTicketServiceAreaForm,
  ticketServiceAreaSchema,
} from "./TicketServiceAreaSchema";
import TicketServAreaService from "../../../../../../services/ticketServiceArea.service";
import TicketServiceAreaField from "./TicketServiceAreaField";
import TicketDefaultDocField from "./TicketDefaultDocField";
import { TicketServiceAreaDays } from "./TicketServiceAreaDays/TicketServiceAreaDays";
import { EnableAllTheTime } from "./EnableAllTheTime";
import { EnableSpecialTime } from "./EnableSpecialTime";
import { useNavigate, useParams } from "react-router-dom";

const TICKET_SERVICE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const ticketService = new MainApi(TICKET_SERVICE_ROUTE);

const ticketServiceArea = new TicketServAreaService();

const TICKET_CHANNELS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels/";
const ticketChannelsService = new MainApi(TICKET_CHANNELS_ROUTE);

const translationPath = "chatBot.page.service.ticketServiceArea.";

const TicketServiceArea = () => {
  const navigate = useNavigate();

  const [isSpecialTime, setIsSpecialTime] = useState(false);

  const { eI, id } = useParams();

  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    ticketServiceArea.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    ticketServiceArea.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    ticketServiceArea.getItemById
  );

  const handleSave = (v: TTicketServiceAreaForm) => {
    return save({
      ...v,
      ticketChannelId: v.ticketChannel ? v.ticketChannel.id : null,
      ticketServiceAreaId: v.ticketServiceArea ? v.ticketServiceArea.id : null,
      ticketServiceId: v.ticketService.id,
      ticketDefaultDocId: v.ticketDefaultDoc ? v.ticketDefaultDoc.id : null,
      displayOrder: v.displayOrder,
      summary: v.summary,
    });
  };

  const updateHandler = (v: TTicketServiceAreaForm, id: string) =>
    update({
      item: {
        ...v,
        ticketChannelId: v.ticketChannel ? v.ticketChannel.id : null,
        ticketServiceAreaId: v.ticketServiceArea
          ? v.ticketServiceArea.id
          : null,
        ticketServiceId: v.ticketService.id,
        ticketDefaultDocId: v.ticketDefaultDoc ? v.ticketDefaultDoc.id : null,
        displayOrder: v.displayOrder,
        summary: v.summary,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceAreaForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            displayOrder: data?.displayOrder.toString(),
          },
          success: success,
        };
      }
      return { data, success };
    },
    [get]
  );

  const saveRedirectHandler = useCallback(
    (data: any) => {
      if (!data || !data.id) return;
      if (isSpecialTime) {
        navigate(
          `/e/${eI}/dashboard/settings/chatBot/ticketServiceArea/${data.id}/edit`
        );
      } else {
        navigate(`/e/${eI}/dashboard/settings/chatBot/ticketServiceArea/`);
      }
    },
    [navigate, eI, isSpecialTime]
  );

  const isSpecialTimeHandler = useCallback((value: boolean) => {
    setIsSpecialTime(value);
  }, []);

  const loading = sLoading || uLoading || gLoading;

  return (
    <>
      <FormContainer<TTicketServiceAreaForm>
        saveFunction={handleSave}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "ticket_service_area")}
        schema={ticketServiceAreaSchema}
        redirectOnSave={!id ? false : true}
        onSuccess={!id ? saveRedirectHandler : undefined}
        backUrl={`/e/${eI}/dashboard/settings/chatBot/ticketServiceArea`}
        customHeight={"fit-content"}
        subtitle={t(translationPath + "new_ticket_service_area")}
        subtitleWatchField={"name"}
        defaultValues={{
          enableAllTheTime: false,
          enableSpecialTime: false,
        }}
        fields={(type) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} textAlign={"end"}>
              <SwitchInput
                name="enable"
                label={t(translationPath + "enable")}
              />
              <SwitchInput
                name="isIdentification"
                label={t(translationPath + "identification")}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormInput
                size="small"
                name="code"
                fullWidth
                label={t(translationPath + "code") || "Code"}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormInput
                size="small"
                name="displayOrder"
                type="number"
                fullWidth
                label={t(translationPath + "display_order") || "Display order"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormEmojiTextInput
                size="small"
                name="name"
                fullWidth
                label={t(translationPath + "name") || "Name"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AutoComplete
                getOptionLabel={(option) => {
                  return option.name;
                }}
                size="small"
                label={t(translationPath + "ticket_services")}
                name="ticketService"
                request={ticketService.list}
              />
            </Grid>

            <TicketServiceAreaField />

            <Grid item xs={6} sm={6}>
              <AutoComplete
                getOptionLabel={(option) => {
                  return option.name;
                }}
                size="small"
                label={t(translationPath + "ticket_channel")}
                name="ticketChannel"
                request={ticketChannelsService.list}
              />
            </Grid>

            <TicketDefaultDocField />

            <Grid item xs={12} sm={12}>
              <FormEmojiTextInput
                size="small"
                name="summary"
                fullWidth
                label={t(translationPath + "summary") || "Summary"}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <EnableAllTheTime />
              <EnableSpecialTime onIsSpecialTime={isSpecialTimeHandler} />
            </Grid>
          </Grid>
        )}
      />
      {isSpecialTime && <TicketServiceAreaDays />}
    </>
  );
};

export default TicketServiceArea;
