import { z } from "zod";

const translationPath = "components.messages.errors.";

const evaluatiomFormSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "evaluation_form" }
);

const tasyScheduleRecordsSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const tasyScheduleClassificationsSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const tasyScheduleTypesSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const tasyHealthInsurancesSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const establishmentsSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const automationActionSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
  description: z.string().nullish(),
});

const ticketAutomationSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    automationAction: automationActionSchema,
    isSendFile: z.boolean(),
    allowTransfer: z.boolean(),
    allowForm: z.boolean(),
  },
  { invalid_type_error: translationPath + "ticket_automation" }
);

export const messageSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    exception: z.boolean(),
    send: z.boolean(),
    name: z.string().min(1, translationPath + "name"),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
    evaluationForm: evaluatiomFormSchema.nullish(),
    ticketAutomation: ticketAutomationSchema.nullish(),
    tasyScheduleRecords: z.array(tasyScheduleRecordsSchema).optional(),
    tasyScheduleClassifications: z
      .array(tasyScheduleClassificationsSchema)
      .optional(),
    tasyScheduleTypes: z.array(tasyScheduleTypesSchema).optional(),
    tasyHealthInsurances: z.array(tasyHealthInsurancesSchema).optional(),
    establishments: z.array(establishmentsSchema).optional(),
  })
  .superRefine((val, ctx) => {
    if (val.ticketAutomation) {
      if (val.ticketAutomation.allowForm) {
        if (!val.evaluationForm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: translationPath + "evaluation_form",
            path: ["evaluatiomForm"],
          });
        }
      }
    }
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_bigger",
        path: ["initialDate"],
      });
    }
    if (val.exception === true) {
      if (
        (!val.establishments || val.establishments.length === 0) &&
        (!val.tasyHealthInsurances || val.tasyHealthInsurances.length === 0) &&
        (!val.tasyScheduleRecords || val.tasyScheduleRecords.length === 0) &&
        (!val.tasyScheduleClassifications ||
          val.tasyScheduleClassifications.length === 0) &&
        (!val.tasyScheduleTypes || val.tasyScheduleTypes.length === 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "exception",
          path: ["exception"],
        });
      }
    }
  });

export type TMessageForm = z.infer<typeof messageSchema>;
