import { Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { getEIFromUrl } from "../../services/establishment.service";
import DynamicIcon from "../UI/DynamicIcon";
import { IMenuRoute } from "../../services/menuRoute.service";
import SettingsSubmenuItem from "./SettingsSubmenuItem";
import useCoordinatesConjuctions from "../../hooks/useCoordinatesConjunctions";

interface ISettingsMenuItemProps {
  name: string;
  link: string;
  icon: string | null;
  submenuDescription?: string;
  description: string;
  submenu?: IMenuRoute[];
}

const SettingsMenuItem = ({
  link,
  name,
  icon,
  submenu,
  submenuDescription,
  description,
}: ISettingsMenuItemProps) => {
  const navigate = useNavigate();

  const eI = getEIFromUrl();

  const translatedName = useCoordinatesConjuctions(name);

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const hasSubmenu = description === submenuDescription;

  const toggleSubmenu = () =>
    setSubmenuOpened((oldState) => (oldState = !oldState));


  return (
    <>
      <Stack
        height={40}
        sx={(t) => ({
          paddingInline: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 1,
          cursor: "pointer",
          transition: "all 0.15s ease-in-out",
          "&:hover": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[100]
                : t.palette.grey[900],
            color:
              t.palette.mode === "light"
                ? t.palette.primary.main
                : t.palette.primary.light,
          },
        })}
        onClick={() => {
          if (hasSubmenu) {
            toggleSubmenu();
          } else {
            navigate(`/e/${eI}/dashboard${link}`);
          }
        }}
      >
        <Stack flexDirection={"row"} gap={1}>
          {icon && <DynamicIcon icon={icon} />}
          <Typography>{translatedName}</Typography>
        </Stack>
        {hasSubmenu && (
          <motion.div
            animate={{ rotate: submenuOpened ? 0 : 180 }}
            transition={{ type: "tween", duration: 0.2, ease: "easeOut" }}
            style={{ height: "24px" }}
          >
            <KeyboardArrowDownIcon />
          </motion.div>
        )}
      </Stack>
      <Stack gap={1.5}>
        {submenuOpened &&
          submenu?.map((item) => (
            <SettingsSubmenuItem
              icon={item.iconClass}
              link={item.link}
              name={item.name}
              key={item.id}
            />
          ))}
      </Stack>
      <Divider sx={{ marginBlock: 1 }} />
    </>
  );
};

export default SettingsMenuItem;
