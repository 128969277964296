import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteUncontrolled,
  ISelectType,
  ModalFilters,
  Table,
  useFetch,
} from "@4uhub/lib4uhub";

import MainApi from "../../../../../../services/mainApi.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IHealthProgramsFundraising } from "../../../../../../models/health-programs-fundraising";
import { Chip } from "@mui/material";
import ApproveCaptation from "./ApproveCaptation";
import DisapproveCaptation from "./DisapproveCaptation";

const HEALTH_PROGRAMS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/HealthProgramCaptation/GetHealthProgramCaptationsWithPagination";
const healthProgramsService = new MainApi<IHealthProgramsFundraising>(
  HEALTH_PROGRAMS_ROUTE
);

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const translationPath = "page.4uHcp.hcp.fundraising.";

const FundraisingPage = () => {
  const [refresh, setRefresh] = useState(0);

  const [filterParams, setFilterParams] = useState<
    Record<string, any> | undefined
  >();

  const [captationTypes, setCaptationTypes] = useState<ISelectType[]>([]);

  const [captationStatus, setCaptationStatus] = useState<ISelectType[]>([]);

  const [selectedType, setSelectedType] = useState<ISelectType | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<ISelectType | null>(
    null
  );

  const { sendRequest } = useFetch(genericService.list);

  const fetchCaptationTypes = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "HealthProgramCaptationType",
    });
    if (data && success) {
      setCaptationTypes(data);
    }
  }, [sendRequest]);

  const fetchCaptationStatus = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "HealthProgramCaptationStatus",
    });
    if (data && success) {
      setCaptationStatus(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchCaptationTypes();
    fetchCaptationStatus();
  }, [fetchCaptationStatus, fetchCaptationTypes]);

  const filterHandler = () => {
    setFilterParams({
      HealthProgramCaptationStatusId: selectedStatus?.id,
      HealthProgramCaptationTypeId: selectedType?.id,
    });
  };

  const cleanFilterHandler = () => {
    setSelectedStatus(null);
    setSelectedType(null);
    setFilterParams(undefined);
  };

  const navigate = useNavigate();

  const { healthProgramId } = useParams();

  const { t } = useTranslation();

  const refreshHandler = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, []);

  const columns: GridColDef<IHealthProgramsFundraising>[] = [
    {
      field: "healthProgram",
      headerName: t(translationPath + "healthProgram") || "Name",
      flex: 1,
      renderCell: (params) => {
        return params.value?.name;
      },
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
      flex: 1,
    },
    {
      field: "healthProgramCaptationType",
      headerName: t(translationPath + "captation_type") || "Captation type",
      flex: 1,
      maxWidth: 300,
      renderCell: (params) => {
        return params.value ? params.value.name : "-";
      },
    },
    {
      field: "healthProgramCaptationStatus",
      headerName: t(translationPath + "captation_status") || "Captation status",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => {
        if (!params.value) return "-";

        const code = params.value?.code;
        return (
          <Chip
            size="small"
            label={params.value.name}
            color={
              code === "1" ? "success" : code === "2" ? "error" : "default"
            }
          />
        );
      },
    },
    {
      field: "approveCaptation",
      headerName: "",
      maxWidth: 170,
      renderCell: (params) => {
        if (params.row.healthProgramCaptationStatus.code !== "3") return "-";

        return (
          <ApproveCaptation
            healthProgramCaptationId={params.row.id}
            onRefresh={refreshHandler}
          />
        );
      },
    },
    {
      field: "disaprroveCaptation",
      headerName: "",
      maxWidth: 170,
      renderCell: (params) => {
        if (params.row.healthProgramCaptationStatus.code !== "3") return "-";

        return (
          <DisapproveCaptation
            healthProgramCaptationId={params.row.id}
            onRefresh={refreshHandler}
          />
        );
      },
    },
  ];

  const handleRowClick = (
    params: GridRowParams<IHealthProgramsFundraising>
  ) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      HealthProgramId: healthProgramId,
      refresh: refresh ? undefined : undefined,
      ...filterParams,
    };
  }, [healthProgramId, refresh, filterParams]);

  return (
    <Table<IHealthProgramsFundraising>
      showDefaultMacroFilters={false}
      service={healthProgramsService}
      columns={columns}
      title={t(translationPath + "fundraising")}
      searchInput
      onRowClick={handleRowClick}
      defaultPageSize={20}
      backButton
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      showButton={false}
      showDeleteButton={false}
      defaultActions={false}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a fundrasing"
      }
      macroFilters={() => (
        <ModalFilters
          actionsDisabled={!selectedStatus && !selectedType}
          badgeCount={
            filterParams
              ? Object.keys(filterParams).filter(
                  (chave) => filterParams[chave] !== undefined
                ).length
              : 0
          }
          onCleanFilter={cleanFilterHandler}
          onFilter={filterHandler}
        >
          <>
            <AutoCompleteUncontrolled
              label={t(translationPath + "captation_type") || "Captation type"}
              size="small"
              value={selectedType}
              onValueSelected={(value) => setSelectedType(value)}
              options={captationTypes}
            />
            <AutoCompleteUncontrolled
              label={
                t(translationPath + "captation_status") || "Captation status"
              }
              size="small"
              value={selectedStatus}
              onValueSelected={(value) => setSelectedStatus(value)}
              options={captationStatus}
            />
          </>
        </ModalFilters>
      )}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default FundraisingPage;
