import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  ISelectType,
  QPFQuickRegisterForm,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../services/mainApi.service";
import { useCallback, useEffect, useState } from "react";
import WorkGroupLanguages from "./WorkGroupLanguages";
import {
  createWorkGroup,
  getWorkGroupById,
  updateWorkGroupById,
} from "../../../../../services/workGroup.service";
import { TWorkGroupForm, workGroupSchema } from "./WorkGroupSchema";

const translationPath = "page.4uHcp.hcp.workGroups.";

const WORK_GROUP_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroupType/";
const workGroupTypeService = new MainApi<ISelectType>(WORK_GROUP_TYPE_ROUTE);

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const WorkGroup = () => {
  const { t } = useTranslation();

  const [allLanguages, setAllLanguages] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(languageService.list);

  const fetchLanguages = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setAllLanguages(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const { sendRequest: save, loading: sLoading } = useFetch(createWorkGroup);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateWorkGroupById);

  const { sendRequest: get, loading: gLoading } = useFetch(getWorkGroupById);

  const saveHandler = (v: TWorkGroupForm) =>
    save({
      ...v,
      healthProgramWorkGroupTypeId: v.healthProgramWorkGroupType.id,
      individualIds: v.healthProgramWorkGroupMembers
        ? v.healthProgramWorkGroupMembers.map((member) => member.id)
        : [],
      healthProgramWorkGroupLanguages: v.healthProgramWorkGroupLanguages.map(
        (workGroupLang) => ({
          ...workGroupLang,
          languageId: workGroupLang.language.id,
        })
      ),
    });

  const updateHandler = (v: TWorkGroupForm, id: string) =>
    update({
      item: {
        ...v,
        healthProgramWorkGroupTypeId: v.healthProgramWorkGroupType.id,
        individualIds: v.healthProgramWorkGroupMembers
          ? v.healthProgramWorkGroupMembers.map((member) => member.id)
          : [],
        healthProgramWorkGroupLanguages: v.healthProgramWorkGroupLanguages.map(
          (workGroupLang) => ({
            ...workGroupLang,
            languageId: workGroupLang.language.id,
          })
        ),
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TWorkGroupForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TWorkGroupForm = {
          ...data,
          healthProgramWorkGroupType: data.workGroupType,
          healthProgramWorkGroupLanguages:
            data.healthProgramWorkGroupLanguages.map((workGroupLang) => ({
              language: workGroupLang.language,
              name: workGroupLang.name,
              description: workGroupLang.description,
            })),
          healthProgramWorkGroupMembers: data.healthProgramWorkGroupMembers.map(
            (member) => ({
              id: member.individual.id,
              name: member.individual.name,
            })
          ),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  if (allLanguages.length === 0) return null;

  return (
    <FormContainer<TWorkGroupForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "work_group")}
      schema={workGroupSchema}
      subtitle={t(translationPath + "new_work_group")}
      subtitleWatchField={"healthProgramWorkGroupType.name"}
      defaultValues={{
        healthProgramWorkGroupLanguages: [
          {
            name: "",
            description: "",
            language: allLanguages[0],
          },
        ],
      }}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "work_group_type")}
              name="healthProgramWorkGroupType"
              request={workGroupTypeService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <QPFQuickRegisterForm
              name="healthProgramWorkGroupMembers"
              label={
                t(translationPath + "work_group_members") ||
                "Work Group Members"
              }
              multiple
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <WorkGroupLanguages allLanguages={allLanguages} />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default WorkGroup;
