import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useCallback } from "react";

import { TAppointment, appointmentSchema } from "./schema";
import Patient from "./Steps/Patient";
import { IStep } from "./Stepper/models";
import FormStepper from "./Stepper/FormStepper";
import Insurance from "./Steps/Insurance";
import SearchAvailability from "./Steps/SearchAvailability/SearchAvailability";
import Schedule from "./Steps/Schedule/Schedule";
import Confirm from "./Steps/Confirm/Confirm";
import { useConfirm } from "./Steps/Confirm/useConfirm";
import { ETasyScheduleTypeCode } from "../../../model";

const STEPS: IStep<TAppointment>[] = [
  {
    label: "components.scheduleAssistant.newSchedule.steps.patient.title",
    element: <Patient />,
    fields: ["patient"],
  },
  {
    label: "components.scheduleAssistant.newSchedule.steps.insurance.title",
    element: <Insurance />,
    fields: ["insurance"],
  },
  {
    label: "components.scheduleAssistant.newSchedule.steps.search.title",
    element: <SearchAvailability />,
    fields: ["search"],
  },
  {
    label: "components.scheduleAssistant.newSchedule.steps.schedule.title",
    element: <Schedule />,
    fields: ["schedule"],
  },
  {
    label: "components.scheduleAssistant.newSchedule.steps.confirm",
    element: <Confirm />,
    fields: [],
  },
];

interface INewScheduleProps {
  onFinishStepper: () => void;
}

const NewSchedule: React.FC<INewScheduleProps> = ({ onFinishStepper }) => {
  const methods = useForm<TAppointment>({
    resolver: zodResolver(appointmentSchema),
    mode: "onChange",
    defaultValues: {
      search: {
        type: ETasyScheduleTypeCode.CONSULTATION,
        date: new Date(),
      },
    },
  });

  const { t } = useTranslation();

  const { scheduleConsultation, error } = useConfirm();

  const onFinish = useCallback(async () => {
    const v = methods.getValues();
    await scheduleConsultation(v);

    onFinishStepper();
  }, [scheduleConsultation, methods, onFinishStepper]);

  return (
    <Form<TAppointment> {...methods}>
      <FormStepper<TAppointment>
        steps={STEPS}
        error={error}
        finishLabel={t("components.scheduleAssistant.newSchedule.sched")!}
        onFinish={onFinish}
      />
    </Form>
  );
};

export default NewSchedule;
