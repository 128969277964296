import { useMemo, useState } from "react";
import MainApi from "../../../../services/mainApi.service";
import { ITasyCalendarLogs } from "../../../../models/tasy-calendar-logs";
import { Table } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { format, isValid } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Stack } from "@mui/material";
import FiltersActions from "../../../../components/FiltersActions/FiltersActions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const FORU_BUS_SCHED_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/ForuBusSchedCtrl";

const foruBusSchedService = new MainApi<ITasyCalendarLogs>(
  FORU_BUS_SCHED_ROUTE
);

const translationPath = "page.4uBus.logs.tasyCalendar.";

const TasyCalendarsLogsPage = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const [update, setUpdate] = useState(0);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isValidDate = (date: Date | null): boolean => {
    if (!date) return false;
    return isValid(date);
  };

  const props = useMemo(() => {
    return {
      StartDate: selectedDate
        ? selectedDate?.toISOString().substring(0, 10)
        : undefined,

      EndDate: selectedEndDate
        ? selectedEndDate?.toISOString().substring(0, 10)
        : undefined,

      Update: update,
    };
  }, [selectedDate, selectedEndDate, update]);

  const updateHandler = () => {
    setUpdate((prev) => prev + 1);
  };

  const columns: GridColDef<ITasyCalendarLogs>[] = [
    {
      field: "typeCode",
      headerName: t(translationPath + "type_code") || "Type Code",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "identifier",
      headerName: t(translationPath + "identifier") || "Identifier",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "calendarIdentifier",
      headerName:
        t(translationPath + "calendar_identifier") || "Calendar Identifier",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "calendarDate",
      headerName: t(translationPath + "calendar_date") || "Calendar Date",
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        });
      },
    },
    {
      field: "status",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 100,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "individualIdentifier",
      headerName:
        t(translationPath + "individual_identifier") || "Individual Identifier",
      maxWidth: 200,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "lastModified",
      headerName: t(translationPath + "last_modified") || "Last Modified",
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
  ];

  return (
    <Table<ITasyCalendarLogs>
      showDefaultMacroFilters={false}
      service={foruBusSchedService}
      columns={columns}
      title={t(translationPath + "title")}
      searchInput={false}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      serviceProps={props}
      showDelete={false}
      showDeleteButton={false}
      showButton={false}
      defaultActions={false}
      searchInputPlaceHolder={t(translationPath + "search") || ""}
      addButtonLabel={t(translationPath + "add_access_token") || ""}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
      macroFilters={() => {
        return (
          <Stack flexDirection={"row"} gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                  field: {
                    clearable: false,
                  },
                }}
                onChange={(v) => {
                  if (v) {
                    if (isValidDate(v)) {
                      const newValue = new Date(v).setHours(15);
                      setSelectedDate(new Date(newValue));
                    }
                  }
                }}
                format={"dd/MM/yyyy"}
              />

              <DatePicker
                value={selectedEndDate}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                  field: {
                    clearable: true,
                    onClear: () => setSelectedEndDate(null),
                  },
                }}
                onChange={(v) => {
                  if (v) {
                    if (isValidDate(v)) {
                      const newValue = new Date(v).setHours(15);
                      if (newValue > selectedDate.setHours(15)) {
                        setSelectedEndDate(new Date(newValue));
                      }
                    }
                  } else {
                    setSelectedEndDate(null);
                  }
                }}
                format={"dd/MM/yyyy"}
              />
            </LocalizationProvider>
            <FiltersActions
              refreshHandler={updateHandler}
              showCleanBtn={false}
            />
          </Stack>
        );
      }}
    />
  );
};

export default TasyCalendarsLogsPage;
