import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMenuSlice } from "../../models/menu";
import { IMenuRoute } from "../../services/menuRoute.service";

const MENU_INITIAL_STATE: IMenuSlice = {
  items: [],
};

export const slice = createSlice({
  name: "menu",
  initialState: MENU_INITIAL_STATE,
  reducers: {
    setItems(state: IMenuSlice, action: PayloadAction<IMenuRoute[]>) {
      return (state = { ...state, items: action.payload });
    },
    setOpenItem(state, actiom: PayloadAction<IMenuRoute>) {
      return (state = { ...state, openItem: actiom.payload });
    },
  },
});

export const menuSliceActions = slice.actions;

export default slice.reducer;
